import React, { useEffect, useCallback, useState } from "react";
import styled from "styled-components";

import Modal from "components/Modal";

import {
  useRidersStore,
  useReallocationStore,
  useModalStore,
} from "stores/hooks";

import { setMobileDash } from "utils/common";
import { riderId } from "utils/params";

const ModalHeader = styled(Modal.Header)`
  background-color: #fff;
`;
const ModalBody = styled(Modal.Body)`
  background-color: #fff;
`;
const ModalFooter = styled(Modal.Footer)`
  background-color: #fff;
`;

const Title = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
`;

const SearchWrap = styled.div`
  display: flex;
  align-items: cetner;
`;

const SearchInput = styled.input`
  flex: 1;
  height: 40px;
  font-size: 16px;
`;

const SearchButton = styled.button`
  width: 80px;
  font-size: 16px;
  border: none;
  background-color: #e94f1f;
  color: #fff;
  margin-left: 5px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  border-top-width: 1px;
  border-top-color: #eee;
  align-items: center;
`;
const ItemStar = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 10px;
  background-image: url(/assets/images/common/${({ active }) =>
    active ? "star_on" : "star_off"}.png);
  background-size: contain;
  background-repeat: no-repeat;
`;

const ItemName = styled.div`
  width: 80px;
  font-size: 16px;
  padding-right: 10px;
`;
const ItemMobile = styled.div`
  font-size: 16px;
  flex: 1;
`;
const ItemCheckbox = styled.div`
  width: 32px;
  height: 32px;
  background-image: url(/assets/images/common/${({ active }) =>
    active ? "check_on" : "check"}.png);
  background-size: contain;
`;

const Button = styled.div`
  flex: 1;
  text-align: center;
  font-size: 16px;

  ${({ orange }) => orange && `color: #e94f1f`}
`;

const More = styled.div`
  font-size: 16px;
  text-align: center;
  padding: 10px 0;
  background-color: #eee;
  margin-left: -20px;
  margin-right: -20px;
`;

const RiderComponent = ({
  rider,
  isFavo,
  isSelected,
  onClickFavo,
  onClickSelect,
}) => {
  const handleClickFavo = () => {
    onClickFavo(rider);
  };

  const handleClickSelect = () => {
    onClickSelect(rider);
  };

  return (
    <Item>
      <ItemStar active={isFavo} onClick={handleClickFavo} />
      <ItemName onClick={handleClickSelect}>{rider.name}</ItemName>
      <ItemMobile onClick={handleClickSelect}>{rider.mobile1}</ItemMobile>
      <ItemCheckbox onClick={handleClickSelect} active={isSelected} />
    </Item>
  );
};

const RidersComponent = ({ selectRider, selectedRider }) => {
  const { favoRiders, riders } = useRidersStore().state;

  const selectFavoRider = (_selectedRider) => {
    if (window.ReactNativeWebView && _selectedRider) {
      let favoRider = {
        id: _selectedRider.id,
        name: _selectedRider.name,
        mobile1: _selectedRider.mobile1,
        isFavo: true,
      };

      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "selectFavoRider",
          rider: favoRider,
        }),
      );
    }
  };

  return (
    <div>
      {riders.map((el) => {
        let favoRiderIndex = Array.isArray(favoRiders)
          ? favoRiders.findIndex((fri) => fri.id === el.id)
          : -1;

        if (Number(riderId) !== Number(el.id) && favoRiderIndex < 0) {
          return (
            <RiderComponent
              key={el.id}
              rider={el}
              isFavo={false}
              isSelected={el.id === selectedRider.id}
              onClickFavo={selectFavoRider}
              onClickSelect={selectRider}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};
const FavoRidersComponent = ({ selectRider, selectedRider }) => {
  const { favoRiders } = useRidersStore().state;

  const unselectFavoRider = (_selectedRider) => {
    if (window.ReactNativeWebView && _selectedRider) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "unselectFavoRider",
          riderId: _selectedRider.id,
        }),
      );
    }
  };

  return (
    <div>
      {favoRiders.map((el) => {
        if (Number(riderId) !== Number(el.id)) {
          return (
            <RiderComponent
              key={el.id}
              rider={el}
              isFavo={true}
              isSelected={el.id === selectedRider.id}
              onClickFavo={unselectFavoRider}
              onClickSelect={selectRider}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default function RidersModal({ onSubmitAfter }) {
  const { query } = useRidersStore().state;
  const { ...ridersActions } = useRidersStore();

  const { selectedDeliveries } = useReallocationStore().state;
  const { ...reallocationActions } = useReallocationStore();

  const { closeModal } = useModalStore();

  useEffect(() => {
    fetchRiders();
  }, [query.page, query.text]);

  const [selectedRider, setSelectedRider] = useState({});
  const [text, setText] = useState("");

  const selectRider = (_selectedRider) => {
    setSelectedRider(_selectedRider);
  };

  const fetchRiders = useCallback(async () => {
    if (query.pageCount >= query.page) {
      try {
        await ridersActions.fetchAll(query.page);
      } catch (e) {
        alert(`라이더 목록을 가져올 수 없습니다. \n에러메시지: ${e.message}`);
      }
    }
  }, [ridersActions.fetchAll, query]);

  const handleChangeText = useCallback((e) => {
    setText(e.target.value);
  }, []);

  const handleClickSearch = useCallback(() => {
    ridersActions.setQuery({
      text,
      page: 1,
    });
  }, [text, ridersActions.setQuery]);

  const handleClickMore = useCallback(() => {
    ridersActions.setQuery({
      page: query.page + 1,
    });
  }, [ridersActions.setQuery, query.page]);

  const rearrangeBulk = () => {
    if (selectedDeliveries.length > 0 && selectedRider.id) {
      let _rearrangeDeliveries = [];

      selectedDeliveries.forEach((el) => {
        if (!el.reallocatedRiderId) {
          _rearrangeDeliveries.push(el.bookId);
        }
      });

      if (_rearrangeDeliveries.length > 0) {
        if (
          window.confirm(
            `${_rearrangeDeliveries[0]} 포함 ${
              _rearrangeDeliveries.length
            }건을 ${selectedRider.name}(${setMobileDash(
              selectedRider.mobile1,
            )}) 기사에게 재배차 요청하시겠습니까? (${
              selectedDeliveries.length - _rearrangeDeliveries.length
            }건 제외)`,
          )
        ) {
          submitRearrangeBulk(_rearrangeDeliveries);
        }
      } else {
        alert(`재배차 처리할 배송건이 없습니다. 배송건을 다시 선택해 주세요.`);
      }
    }
  };

  const submitRearrangeBulk = useCallback(
    async (_rearrangeDeliveries) => {
      try {
        const data = {
          bookIds: _rearrangeDeliveries,
          type: selectedDeliveries[0].statusRearrangeKey,
          reallocatedRiderId: selectedRider.id,
        };

        await reallocationActions.reallocateDeliveries({
          data,
        });

        setSelectedRider({});

        reallocationActions.cancelRearrangeMode();

        closeModal();

        onSubmitAfter();
      } catch (e) {
        alert(
          `${selectedDeliveries[0].statusString}재배차 요청에 실패하였습니다. \n에러메시지: ${e.message}`,
        );
      }
    },
    [
      selectedDeliveries,
      selectedRider,
      reallocationActions.reallocateDeliveries,
      reallocationActions.cancelRearrangeMode,
    ],
  );

  return (
    <Modal className="modal-center">
      <ModalHeader>
        <Title>
          {selectedDeliveries.length > 0 && selectedDeliveries[0].status === 1
            ? "수거"
            : "배송"}{" "}
          재배차 기사 선택
        </Title>
      </ModalHeader>
      <ModalBody>
        <SearchWrap>
          <SearchInput type="text" value={text} onChange={handleChangeText} />
          <SearchButton type="button" onClick={handleClickSearch}>
            검색
          </SearchButton>
        </SearchWrap>
        <FavoRidersComponent
          selectRider={selectRider}
          selectedRider={selectedRider}
        />
        <RidersComponent
          selectRider={selectRider}
          selectedRider={selectedRider}
        />
        {query.page <= query.pageCount && (
          <More onClick={handleClickMore}>더보기</More>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal}>닫기</Button>
        <Button orange onClick={rearrangeBulk}>
          재배차요청
        </Button>
      </ModalFooter>
    </Modal>
  );
}
