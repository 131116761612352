import React, { createContext, useContext, useReducer } from "react";

import { toast } from "react-toastify";

import api from "services/Api";

import { reOrder } from "utils/common";
import { riderTokenNew } from "utils/params";

export const OrderContext = createContext({});

const CONSTANTS = {
  START_ORDER_MODE: "START_ORDER_MODE",
  CANCEL_ORDER_MODE: "CANCEL_ORDER_MODE",
  SET_NEW_ORDER_LIST: "SET_NEW_ORDER_LIST",
};

const INITIAL_STATE = {
  isOrderMode: false,
  newOrderList: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.START_ORDER_MODE:
      return {
        ...state,
        isOrderMode: true,
      };
    case CONSTANTS.CANCEL_ORDER_MODE:
      return {
        ...state,
        isOrderMode: false,
        newOrderList: [],
      };
    case CONSTANTS.SET_NEW_ORDER_LIST:
      return {
        ...state,
        newOrderList: action.newOrderList,
      };
    default:
      return INITIAL_STATE;
  }
};

export const OrderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <OrderContext.Provider value={{ state, dispatch }}>
      {children}
    </OrderContext.Provider>
  );
};

export const useOrderStore = () => {
  const { dispatch, state } = useContext(OrderContext);

  const startOrderMode = () => {
    dispatch({ type: CONSTANTS.START_ORDER_MODE });
  };

  const cancelOrderMode = () => {
    dispatch({ type: CONSTANTS.CANCEL_ORDER_MODE });
  };

  // 기존 순서 그대로 유지할 경우 orderList 가져오기
  const initNewOrderList = (orderList) => {
    dispatch({ type: CONSTANTS.SET_NEW_ORDER_LIST, newOrderList: orderList });
  };

  const resetNewOrderList = () => {
    dispatch({ type: CONSTANTS.SET_NEW_ORDER_LIST, newOrderList: [] });
  };

  const selectOrderDelivery = ({ bookId, receiverAddress, samePlaces }) => {
    let orderList = [...state.newOrderList];

    const prevIndex = orderList.findIndex((el) => el.bookId === bookId);

    if (prevIndex > -1) {
      orderList.splice(prevIndex, 1);

      if (samePlaces && samePlaces.length > 0) {
        samePlaces.forEach((el) => {
          let index = orderList.findIndex((_el) => _el.bookId === el.bookId);

          if (index > -1) orderList.splice(index, 1);
        });
      }
    } else {
      let order = orderList.length + 1;
      orderList.push({
        bookId,
        order,
      });

      if (samePlaces && samePlaces.length > 0) {
        samePlaces.forEach((el) => {
          orderList.push({
            bookId: el.bookId,
            order,
          });
        });
      }
    }

    toast(
      `${
        prevIndex > -1
          ? "순서해제  l  "
          : `${state.newOrderList.length + 1}번  l  `
      }${receiverAddress}`,
      {
        className: "toast-background",
        bodyClassName: "toast-body",
        progressClassName: "fancy-progress-bar",
      },
    );

    let _newOrderList = reOrder(orderList);

    dispatch({
      type: CONSTANTS.SET_NEW_ORDER_LIST,
      newOrderList: _newOrderList,
    });
  };

  /**
   * 순서지정
   */
  const reorderDeliveries = (data) =>
    api.put(`deliveries/reorder`, riderTokenNew, data);

  return {
    state,
    startOrderMode,
    cancelOrderMode,
    initNewOrderList,
    resetNewOrderList,
    selectOrderDelivery,
    reorderDeliveries,
  };
};
