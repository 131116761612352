import React, { useCallback } from "react";
import styled from "styled-components";
import Modal from "components/Modal";
import Button from "react-bootstrap/Button";

import { useModalStore } from "stores/hooks";

const Title = styled.h2`
  font-size: 16px;
  margin: 0;
  font-weight: bold;
`;

const Memo = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
`;

const StyledButton = styled(Button)`
  padding: 6px 4px;
`;

const RematchButton = styled(Button)`
  width: 100%;
  border: none;
  border-radius: 4px;
  background-color: #fc3f00;
  color: #fff;
  margin-top: 5px;
`;

function BaseAddressesQuickModal({
  addressRoad,
  buildingDongNumber,
  memo,
  onClickEdit,
  onClickDelete,
  onClickOrderUp,
  onClickOrderDown,
  onClickReset,
}) {
  const { closeModal } = useModalStore();

  const handleCancelEdit = useCallback(() => {
    onClickEdit();
    closeModal();
  }, [onClickEdit]);

  const handleCancelDelete = useCallback(() => {
    onClickDelete();
    closeModal();
  }, [onClickDelete]);

  const handleCancelOrderUp = useCallback(() => {
    onClickOrderUp();
    closeModal();
  }, [onClickOrderUp]);

  const handleCancelOrderDown = useCallback(() => {
    onClickOrderDown();
    closeModal();
  }, [onClickOrderDown]);

  const handleClickReset = useCallback(() => {
    onClickReset();
  }, [onClickReset]);

  return (
    <Modal className="modal-center">
      <Modal.Header>
        <Title>주소정보 순서변경/내용수정/삭제</Title>
      </Modal.Header>
      <Modal.Body>
        <Memo>{memo}</Memo>
        <ButtonGroup>
          <StyledButton
            type="button"
            variant="outline-secondary"
            onClick={handleCancelOrderUp}
          >
            위로
          </StyledButton>
          <StyledButton
            type="button"
            variant="outline-secondary"
            onClick={handleCancelOrderDown}
          >
            아래로
          </StyledButton>
          <StyledButton
            type="button"
            variant="outline-secondary"
            onClick={handleCancelEdit}
          >
            수정
          </StyledButton>
          <StyledButton
            type="button"
            variant="outline-secondary"
            onClick={handleCancelDelete}
          >
            삭제
          </StyledButton>
        </ButtonGroup>

        <RematchButton type="button" onClick={handleClickReset}>
          {addressRoad}
          {buildingDongNumber ? ` ${buildingDongNumber}동` : ""} 재등록
        </RematchButton>
      </Modal.Body>
    </Modal>
  );
}

export default BaseAddressesQuickModal;
