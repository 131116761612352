import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react';

import { toast } from "react-toastify";

import api from 'services/Api';

import { riderTokenNew } from "utils/params";

export const ReallocationContext = createContext({});

const CONSTANTS = {
  START_REARRANGE_MODE: 'START_REARRANGE_MODE',
  CANCEL_REARRANGE_MODE: 'CANCEL_REARRANGE_MODE',
  SELECT_DELIVERIES: 'SELECT_DELIVERIES',
};

const INITIAL_STATE = {
  isRearrangeMode: false,
  selectedDeliveries: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.START_REARRANGE_MODE:
      return {
        ...state,
        isRearrangeMode: true,
      };
    case CONSTANTS.CANCEL_REARRANGE_MODE:
      return {
        ...state,
        isRearrangeMode: false,
        selectedDeliveries: [],
      };
    case CONSTANTS.SELECT_DELIVERIES:
      return {
        ...state,
        selectedDeliveries: action.selectedDeliveries,
      };
    default:
      return INITIAL_STATE;
  }
};

export const ReallocationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <ReallocationContext.Provider value={{ state, dispatch }}>{children}</ReallocationContext.Provider>
  );
};

export const useReallocationStore = () => {
  const { dispatch, state } = useContext(ReallocationContext);

  const startRearrangeMode = useCallback(() => {
    dispatch({ type: CONSTANTS.START_REARRANGE_MODE });
  }, [dispatch]);

  const cancelRearrangeMode = useCallback(() => {
    dispatch({ type: CONSTANTS.CANCEL_REARRANGE_MODE });
  }, [dispatch]);

  const selectDelivery = useCallback(({
    bookId, 
    status, 
    statusString, 
    statusRearrangeKey, 
    samePlaces, 
    reallocatedRiderId,
  }) => {
    let selectedDeliveries = [...state.selectedDeliveries];

    const prevIndex = selectedDeliveries.findIndex(
      (el) => el.bookId === bookId
    );
    
    if (prevIndex > -1) {
      selectedDeliveries.splice(prevIndex, 1);

      if (samePlaces && samePlaces.length > 0) {
        samePlaces.forEach((el) => {
          let index = selectedDeliveries.findIndex(
            (_el) => _el.bookId === el.bookId
          );

          if (index > -1) selectedDeliveries.splice(index, 1);
        });
      }
    } else {
      selectedDeliveries.push({ bookId, status, statusString, statusRearrangeKey, reallocatedRiderId });

      if (samePlaces && samePlaces.length > 0) {
        samePlaces.forEach((el) => {
          if (state.selectedDeliveries.length === 0 || el.status === state.selectedDeliveries[0].status) {
            selectedDeliveries.push({
              bookId: el.bookId,
              status: el.status,
              statusString: el.statusString,
              statusRearrangeKey: el.statusRearrangeKey,
              reallocatedRiderId: el.reallocatedRiderId,
            });
          }
        });
      }
    }

    toast(
      `${prevIndex > -1 ? "배차전환해제  l  " : ""}${bookId} ${
        samePlaces && samePlaces.length > 0
          ? `외 ${samePlaces.length}건`
          : ""
      }`,
      {
        className: "toast-background",
        bodyClassName: "toast-body",
        progressClassName: "fancy-progress-bar",
      }
    );

    dispatch({ type: CONSTANTS.SELECT_DELIVERIES, selectedDeliveries });
  }, [
    dispatch,
    state.selectedDeliveries,
  ]);

  /**
   * 재배차 : 복수
   */
  const reallocateDeliveries = useCallback(
    async ({ data }) => await api.put(`deliveries/reallocation`, riderTokenNew, data),
    [riderTokenNew]
  );

  return {
    state,
    startRearrangeMode,
    cancelRearrangeMode,
    selectDelivery,
    reallocateDeliveries,
  };
};