function load(url, cb, err) {
  var element = document.createElement("script");
  var parent = "body";
  var attr = "src";

  element.async = true;
  element.onload = function () {
    cb();
  };
  element.onerror = function () {
    err();
  };
  element[attr] = url;
  document[parent].appendChild(element);
}

export const initMap = (actions) => {
  const url =
    "https://oapi.map.naver.com/openapi/v3/maps.js?ncpClientId=t1v5iom1he";
  load(url, actions);
};
