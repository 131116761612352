import React from "react";
import styled from "styled-components";

const Self = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1000;
  left: 0;
  bottom: 0;
  background-color: rgba(32, 32, 32, 0.8);
  color: #fff;
  display: none;

  ${({ isShow }) => isShow && `display: block;`}
`;

const GuideText = styled.div`
  padding: 12px 0;
  font-size: ${({ theme }) => theme.font16}px;
  text-align: center;
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const Button = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font18}px;
  height: 50px;

  ${({ reallocation }) =>
    reallocation &&
    `
    background-color: #C357F5;
    color: #fff;
  `}

  ${({ waiting }) =>
    waiting &&
    `
    background-color: #FFA800;
    color: #fff;
  `}

  ${({ postponed }) =>
    postponed &&
    `
    background-color: #4350B6;
    color: #fff;
  `}

  ${({ cancel }) =>
    cancel &&
    `
      background-color: #202020;
      color: #fff;
    `}
`;

const RearrangeGuide = ({
  isShow,
  rearrangeCount,
  deliveriesCount,
  onClickSubmitRearrange,
  onClickSubmitPrev,
  onClickSubmitPostponed,
  onClickCancel,
}) => {
  return (
    <Self isShow={isShow}>
      <GuideText>
        배차전환할 마커를 터치해주세요.
        <br />({rearrangeCount}/{deliveriesCount})
      </GuideText>
      <ButtonGroup>
        <Button reallocation onClick={onClickSubmitRearrange}>
          재배차
        </Button>
        <Button waiting onClick={onClickSubmitPrev}>
          대기변경
        </Button>
        <Button postponed onClick={onClickSubmitPostponed}>
          연기변경
        </Button>
        <Button cancel onClick={onClickCancel}>
          취소
        </Button>
      </ButtonGroup>
    </Self>
  );
};

export default RearrangeGuide;
