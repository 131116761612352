import React from "react";
import styled, { css } from "styled-components";
import Modal from "components/Modal";

import { useDeliveriesStore, useOrderStore, useModalStore } from "stores/hooks";

const ModalBody = styled.div`
  background-color: rgba(32, 32, 32, 0.8);
  color: #fff;
`;
const ModalDesc = styled.div`
  text-align: center;
  padding: 12px 0;
  font-size: 16px;
`;
const ModalDescIcon = styled.img`
  height: 23px;
  margin-bottom: 5px;
`;

const OrderButtonGroup = styled.div`
  display: flex;
`;
const OrderButton = styled.div`
  flex: 1;
  background-color: #75ff6f;
  color: #202020;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  height: 50px;

  ${({ cancel }) =>
    cancel &&
    css`
      background-color: #202020;
      color: #fff;
    `}
`;
function OrderConfirmModal({ changeOrder }) {
  const { deliveryStartList } = useDeliveriesStore().state;

  const { newOrderList } = useOrderStore().state;

  const { closeModal } = useModalStore();

  return (
    <Modal>
      <ModalBody>
        <ModalDesc>
          <ModalDescIcon
            src="/assets/images/map/warning.png"
            alt="경고 아이콘"
          />
          <br />
          {deliveryStartList.length - newOrderList.length}건이 배송순서가 지정
          <br />
          되지 않았습니다.
          <br />
          해당건은 순서지정을 하지 않고
          <br />
          나가시겠습니까?
        </ModalDesc>
        <OrderButtonGroup>
          <OrderButton type="button" onClick={closeModal}>
            &#60; 순서지정
          </OrderButton>
          <OrderButton cancel type="button" onClick={changeOrder}>
            나가기 &#62;
          </OrderButton>
        </OrderButtonGroup>
      </ModalBody>
    </Modal>
  );
}

export default OrderConfirmModal;
