import { Delivery, renderStatusByNumber } from "Models/Delivery";

export const renderUpdateLog = (log) => {
  const updated = !!log.text && "내용수정";
  const checked = log.checked ? "단순완료" : "단순취소";

  const writer = log.admin
    ? `관리자: ${log.admin.username}`
    : `라이더: ${log.rider?.name}`;

  return `${
    renderStatusByNumber(log.status) || updated || checked
  } (${writer})`;
};

export class Cs {
  id = null;
  answer = null;
  bookId = null;
  completedAt = null;
  checked = false;
  createdAt = null;
  etc = null;
  image = null;
  text = "";
  updatedAt = null;
  userId = null;
  delivery = {};

  constructor(cs) {
    this.id = cs.id;
    this.answer = cs.answer;
    this.bookId = cs.bookId;
    this.completedAt = cs.completedAt;
    this.checked = cs.checked;
    this.createdAt = cs.createdAt;
    this.etc = cs.etc;
    this.image = cs.image;
    this.text = cs.text;
    this.updateLogs = cs.updateLogs;
    this.updatedAt = cs.updatedAt;
    this.userId = cs.userId;
    this.delivery = cs.delivery;
  }

  get deliveryForMarker() {
    if (this.delivery) {
      let _delivery = this.delivery;
      // _delivery.keyColor = "black";
      // _delivery.keyColorHex = "#000000";
      // _delivery.keyColorHexNew = "#000000";
      // _delivery.statusString = "CS";

      return new Delivery(_delivery, null, true);
    } else {
      return {};
    }
  }

  get csEtcObj() {
    try {
      return this.etc && JSON.parse(this.etc);
    } catch (e) {
      return {};
    }
  }

  get updateLogsObj() {
    try {
      return this.updateLogs && JSON.parse(this.updateLogs);
    } catch (e) {
      return {};
    }
  }

  get updateLogsArray() {
    try {
      const logs = this.updateLogsObj ? Object.entries(this.updateLogsObj) : [];

      logs.sort((a, b) => {
        return new Date(a[0]) > new Date(b[0]) ? -1 : 1;
      });

      return logs;
    } catch (e) {
      return [];
    }
  }

  get withDeliveryCompleted() {
    return this.csEtcObj && this.csEtcObj.withDeliveryCompleted;
  }
}
