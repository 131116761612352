export const PICKUP = {
  key: "pickup",
  name: "수거",
};

export const DELIVER = {
  key: "deliver",
  name: "배송",
};

export const PICKUP_DELIVER = {
  key: "pickup-deliver",
  name: "전체",
};

export const COMPLETED = {
  key: "completed",
  name: "완료",
};

export const COMPLETED_WITH_LOCATION = {
  key: "completedWithLocation",
  name: "완료",
};
