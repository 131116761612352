import React from "react";
import styled from "styled-components";

const Self = styled.div`
  z-index: 1000;
  display: flex;
  fiex-direction: row;
  justify-content: center;
`;

const BottomButton = styled.div`
  margin: 0 4px;
  width: ${({ theme }) => theme.width90}px;
  height: 36px;
  border-radius: 10px;
  background-color: #fff;
  color: #212121;
  font-size: ${({ theme }) => theme.font16}px;
  font-weight: bold;
  letter-spacing: -0.32px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1.7px 4px 0 rgba(0, 0, 0, 0.2);
`;

const Icon = styled.img`
  width: 12px;
  margin-right: 5px;
`;

const BottomButtons = ({ onClickStartOrder, onClickStartRearrange }) => {
  return (
    <Self>
      <BottomButton onClick={onClickStartOrder}>
        <Icon src="/assets/images/map/icon-order.svg" alt="순서지정 아이콘" />
        순서
      </BottomButton>
      <BottomButton onClick={onClickStartRearrange}>
        <Icon
          src="/assets/images/map/icon-rearrange.svg"
          alt="순서지정 아이콘"
        />
        배차
      </BottomButton>
    </Self>
  );
};

export default BottomButtons;
