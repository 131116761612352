import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import Modal from "components/Modal";

import { useDeliveriesStore, useOrderStore } from "stores/hooks";

const ModalBody = styled.div`
  padding: 0 15px;
  font-size: 16px;
  background-color: rgba(32, 32, 32, 0.8);
  border-radius: 25px;
  width: 90%;
  margin: 0 auto 16px;
`;
const ModalButton = styled.button`
  width: 100%;
  height: 53px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin: 0;

  ${({ first }) =>
    first &&
    css`
      border-bottom: 1px solid #808080;
    `}
`;
const ModalButtonIcon = styled.img`
  height: 32px;
  margin-right: 5px;
  margin-top: -3px;
`;

function ResetConfirmModal({ startOrder }) {
  const { orderList } = useDeliveriesStore().state;

  const { ...orderActions } = useOrderStore();

  const resetOrder = useCallback(() => {
    orderActions.resetNewOrderList();

    setTimeout(() => {
      startOrder();
    }, 800);
  }, [orderActions.resetNewOrderList]);

  const keepOrder = useCallback(() => {
    orderActions.initNewOrderList(orderList);

    setTimeout(() => {
      startOrder();
    }, 800);
  }, [orderActions.initNewOrderList, orderList]);

  return (
    <Modal backdrop={true}>
      <ModalBody>
        <ModalButton first type="button" onClick={keepOrder}>
          <ModalButtonIcon
            src="/assets/images/map/arrow.png"
            alt="기존 순서 유지 아이콘"
          />
          기존 순서 유지
        </ModalButton>

        <ModalButton type="button" onClick={resetOrder}>
          <ModalButtonIcon
            src="/assets/images/map/refresh.png"
            alt="새로고침 아이콘"
          />
          처음부터 다시 지정
        </ModalButton>
      </ModalBody>
    </Modal>
  );
}

export default ResetConfirmModal;
