import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import { Delivery } from "Models";

import api from "services/Api";
import { riderTokenNew, riderToken } from "utils/params";

export const DeliveryContext = createContext({});

const CONSTANTS = {
  FETCH_BY_ID: "FETCH_BY_ID",
};

const INITIAL_STATE = {
  delivery: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_BY_ID:
      return {
        ...state,
        delivery: action.delivery,
      };
    default:
      return INITIAL_STATE;
  }
};

export const DeliveryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <DeliveryContext.Provider value={{ state, dispatch }}>
      {children}
    </DeliveryContext.Provider>
  );
};

export const useDeliveryStore = () => {
  const { dispatch, state } = useContext(DeliveryContext);
  const token = riderToken || riderTokenNew;

  /**
   * 배송 상세
   */
  const fetchById = useCallback(
    async ({ bookId }) => {
      const response = await api.get(`deliveries/${bookId}`, token);

      dispatch({
        type: CONSTANTS.FETCH_BY_ID,
        delivery: new Delivery(response),
      });
    },
    [dispatch, token],
  );

  return {
    state,
    fetchById,
  };
};
