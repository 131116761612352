import React, { useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import Form from "react-bootstrap/Form";

import { params } from "utils/params";

import {
  useBaseAddressesStore,
  useDeliveryStore,
  useModalStore,
} from "stores/hooks";

import BaseAddressesQuickModal from "modals/BaseAddressesQuickModal";

const Container = styled.div`
  font-size: 16px;
  padding: 15px;
`;

const Title = styled.h1`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const BaseAddressesWrap = styled.div``;

const BaseAddressGroup = styled.div`
  margin-bottom: 10px;
`;

const BaseAddressGroupTitle = styled.h5`
  font-weight: bold;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 5px;
`;
const BaseAddressWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 6px;
`;

const BaseAddressContent = styled.div`
  white-space: pre-wrap;
  padding-top: 5px;
`;

const FormWrap = styled.div`
  display: flex;
  items-align: center;
  margin-bottom: 10px;
`;

const FormControl = styled(Form.Control)`
  flex: 1;
  resize: none;
  padding: 5px;
`;

const FormButton = styled.button`
  width: 70px;
  border: none;
  border-radius: 4px;
  background-color: #fc3f00;
  color: #fff;
  margin-left: 5px;
`;

const CancelButton = styled.div`
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  color: #999;
  margin-top: 5px;
`;

const BaseAddress = ({
  baseAddressId,
  baseAddressMemo,
  addressRoad,
  buildingDongNumber,
  onClickEdit,
  onAfterUpdate,
}) => {
  const bookId = params.get("bookId");

  const { ...baseAddressesActions } = useBaseAddressesStore();
  const { openModal } = useModalStore();

  const handleClickEdit = useCallback(() => {
    onClickEdit(baseAddressMemo);
  }, [baseAddressMemo]);

  const handleClickDelete = useCallback(async () => {
    if (window.confirm(`${baseAddressMemo.memo}를 삭제하시겠습니까?`)) {
      try {
        await baseAddressesActions.deleteBaseAddress(baseAddressMemo.id);

        onAfterUpdate();
      } catch (e) {
        window.alert(
          `주소지 정보 삭제에 실패했습니다. \n에러메시지: ${e.message}`,
        );
      }
    }
  }, [baseAddressMemo, baseAddressesActions.deleteBaseAddress]);

  const handleClickOrderUp = useCallback(async () => {
    try {
      await baseAddressesActions.setOrder(baseAddressMemo.id, {
        order: "up",
      });

      onAfterUpdate();
    } catch (e) {
      window.alert(
        `주소지 정보 순서 변경에 실패했습니다. \n에러메시지: ${e.message}`,
      );
    }
  }, [baseAddressMemo, baseAddressesActions.setOrder]);

  const handleClickOrderDown = useCallback(async () => {
    try {
      await baseAddressesActions.setOrder(baseAddressMemo.id, {
        order: "down",
      });

      onAfterUpdate();
    } catch (e) {
      window.alert(
        `주소지 정보 순서 변경에 실패했습니다. \n에러메시지: ${e.message}`,
      );
    }
  }, [baseAddressMemo, baseAddressesActions.setOrder]);

  const handleClickReset = useCallback(async () => {
    try {
      if (baseAddressId) {
        await baseAddressesActions.update(baseAddressMemo.id, {
          memo: baseAddressMemo.memo,
          baseAddressId: baseAddressId,
          buildingDongNumber: buildingDongNumber,
        });
      } else {
        await baseAddressesActions.create({
          memo: baseAddressMemo.memo,
          bookId,
        });
        await baseAddressesActions.deleteBaseAddress(baseAddressMemo.id);
      }

      onAfterUpdate();
      window.alert("재등록되었습니다.");
    } catch (e) {
      window.alert(
        `주소지 정보 재등록에 실패했습니다. \n에러메시지: ${e.message}`,
      );
    }
  }, [
    bookId,
    baseAddressId,
    baseAddressMemo,
    buildingDongNumber,
    baseAddressesActions.update,
    baseAddressesActions.create,
    baseAddressesActions.deleteBaseAddress,
  ]);

  const handleClick = useCallback(() => {
    openModal(
      <BaseAddressesQuickModal
        key="base-addresses-quick-modal"
        addressRoad={addressRoad}
        buildingDongNumber={buildingDongNumber}
        memo={baseAddressMemo.memo}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
        onClickOrderUp={handleClickOrderUp}
        onClickOrderDown={handleClickOrderDown}
        onClickReset={handleClickReset}
      />,
    );
  }, [
    addressRoad,
    baseAddressMemo,
    buildingDongNumber,
    handleClickEdit,
    handleClickDelete,
    handleClickOrderDown,
    handleClickOrderUp,
    handleClickReset,
  ]);

  return (
    <BaseAddressWrap>
      <BaseAddressContent onClick={handleClick}>
        {baseAddressMemo.memo}
      </BaseAddressContent>
    </BaseAddressWrap>
  );
};
const BaseAddresses = () => {
  const bookId = params.get("bookId");
  const type = params.get("type");

  const {
    baseAddressId,
    baseAddresses,
    baseAddressesCurrentBuildingDong,
    baseAddressesWithBuildingDongWithAddressRoad,
    baseAddressesWithBuildingDongWithAddress,
    baseAddressesNoBuildingDongWithAddressRoad,
    baseAddressesNoBuildingDongWithAddress,
    fetched,
  } = useBaseAddressesStore().state;
  const { ...baseAddressesActions } = useBaseAddressesStore();

  const { delivery } = useDeliveryStore().state;
  const { ...deliveryActions } = useDeliveryStore();

  const [address, setAddress] = useState(null);
  const [memo, setMemo] = useState("");

  const [editId, setEditId] = useState(null);

  const buildingDongNumber =
    type === "sender"
      ? delivery?.senderBuildingDongNumber
      : delivery?.receiverBuildingDongNumber;

  useEffect(() => {
    if (bookId) {
      fetchDeliveryById();
    }
  }, [bookId]);

  useEffect(() => {
    if (delivery?.bookId === bookId) {
      const _address =
        type === "sender"
          ? delivery.senderAddressRoadWithoutSigungu ||
            delivery.senderAddressWithoutSigungu
          : delivery.receiverAddressRoadWithoutSigungu ||
            delivery.receiverAddressWithoutSigungu;

      setAddress(_address);
      setMemo(delivery.frontdoorPassword);

      fetchAll();
      fetchBaseAddressId();
    }
  }, [delivery, bookId]);

  // 배송 조회
  const fetchDeliveryById = useCallback(async () => {
    try {
      await deliveryActions.fetchById({
        bookId,
      });
    } catch (e) {
      alert(`배송 상세를 가져올 수 없습니다. \n에러메시지: ${e.message}`);
    }
  }, [bookId, deliveryActions.fetchById]);

  // 주소 정보 baseAddressId 조회
  const fetchBaseAddressId = useCallback(async () => {
    try {
      await baseAddressesActions.fetchBaseAddressId({
        addressRoad:
          type === "sender"
            ? delivery.senderAddressRoad
            : delivery.receiverAddressRoad,
      });
    } catch (e) {}
  }, [baseAddressesActions.fetchBaseAddressId, bookId, delivery, type]);

  const fetchAll = useCallback(async () => {
    if (delivery && bookId) {
      try {
        await baseAddressesActions.fetchAll({
          bookId: bookId,
          address:
            type === "sender"
              ? delivery.senderAddress
              : delivery.receiverAddress,
          addressRoad:
            type === "sender"
              ? delivery.senderAddressRoad
              : delivery.receiverAddressRoad,
          buildingDongNumber:
            type === "sender"
              ? delivery.senderBuildingDongNumber
              : delivery.receiverBuildingDongNumber,
        });
      } catch (e) {
        window.alert(`목록을 가져올 수 없습니다. \n에러메시지: ${e.message}`);
      }
    }
  }, [baseAddressesActions.fetchAll, bookId, delivery, type]);

  const handleChange = useCallback((e) => {
    setMemo(e.target.value);
  }, []);

  const handleClickEdit = useCallback((memoObj) => {
    setEditId(memoObj.id);
    setMemo(memoObj.memo);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setEditId(null);
    setMemo("");
  }, []);

  const onSubmit = useCallback(async () => {
    if (memo) {
      if (editId) {
        try {
          await baseAddressesActions.update(editId, {
            memo,
          });

          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ toastMessage: "주소정보를 변경했습니다." }),
            );
          }

          setEditId(null);
          setMemo("");
        } catch (e) {
          window.alert(
            `주소지 정보 수정에 실패했습니다. \n에러메시지: ${e.message}`,
          );
        }
      } else {
        try {
          await baseAddressesActions.create({
            bookId,
            memo,
          });

          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ toastMessage: "주소정보를 추가했습니다." }),
            );
          }

          setMemo("");
        } catch (e) {
          window.alert(
            `주소지 정보 추가에 실패했습니다. \n에러메시지: ${e.message}`,
          );
        }
      }

      fetchAll();
    } else {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ toastMessage: "주소지 정보를 입력해주세요." }),
        );
      }
    }
  }, [
    address,
    delivery,
    memo,
    editId,
    baseAddressesActions.create,
    baseAddressesActions.update,
  ]);

  const onAfterUpdate = useCallback(() => {
    fetchAll();
  }, [delivery, bookId]);

  const baseAddressesCurrentBuildingDongGroupBy =
    baseAddressesCurrentBuildingDong?.reduce((acc, curr) => {
      const { buildingDongNumber } = curr;
      if (acc[buildingDongNumber]) acc[buildingDongNumber].push(curr);
      else acc[buildingDongNumber] = [curr];
      return acc;
    }, {});

  const baseAddressesWithBuildingDongWithAddressRoadGroupBy =
    baseAddressesWithBuildingDongWithAddressRoad?.reduce((acc, curr) => {
      const { buildingDongNumber } = curr;
      if (acc[buildingDongNumber]) acc[buildingDongNumber].push(curr);
      else acc[buildingDongNumber] = [curr];
      return acc;
    }, {});

  const baseAddressesWithBuildingDongWithAddressGroupBy =
    baseAddressesWithBuildingDongWithAddress?.reduce((acc, curr) => {
      const { buildingDongNumber } = curr;
      if (acc[buildingDongNumber]) acc[buildingDongNumber].push(curr);
      else acc[buildingDongNumber] = [curr];
      return acc;
    }, {});

  const baseAddressesNoBuildingDongWithAddressGroupBy =
    baseAddressesNoBuildingDongWithAddress?.reduce((acc, curr) => {
      const { baseAddress } = curr;
      if (acc[baseAddress.address]) acc[baseAddress.address].push(curr);
      else acc[baseAddress.address] = [curr];
      return acc;
    }, {});

  return (
    <Container>
      <Title>
        {address}{" "}
        {type === "sender" && delivery?.senderBuildingDongNumber
          ? `(${delivery.senderBuildingDongNumber})`
          : ""}
        {type === "receiver" && delivery?.displayReceiverBuildingDongNumber
          ? `(${delivery.displayReceiverBuildingDongNumber})`
          : ""}
      </Title>

      <FormWrap>
        <FormControl
          as="textarea"
          rows="2"
          placeholder="추가 정보가 있으면 입력해주세요."
          value={memo}
          onChange={handleChange}
        />
        <FormButton type="button" onClick={onSubmit}>
          {editId ? "수정" : "추가"}
        </FormButton>
      </FormWrap>
      {editId && (
        <CancelButton tabIndex={0} onClick={handleCancelEdit}>
          수정 취소
        </CancelButton>
      )}

      <BaseAddressesWrap>
        {buildingDongNumber ? (
          <>
            {baseAddressesCurrentBuildingDong.length > 0 ||
            baseAddressesWithBuildingDongWithAddressRoad.length > 0 ? (
              <BaseAddressGroupTitle>
                {type === "sender"
                  ? delivery.senderAddressRoad
                  : delivery.receiverAddressRoad}
              </BaseAddressGroupTitle>
            ) : (
              <></>
            )}
            {baseAddressesCurrentBuildingDong.length > 0 && (
              <>
                {Object.entries(baseAddressesCurrentBuildingDongGroupBy).map(
                  ([key, value], index) => (
                    <BaseAddressGroup
                      key={"baseAddressesCurrentBuildingDongGroupBy" + index}
                    >
                      <BaseAddressGroupTitle>
                        {key}동 정보
                      </BaseAddressGroupTitle>
                      {value.map((el) => {
                        return (
                          <BaseAddress
                            key={el.id}
                            baseAddressMemo={el}
                            baseAddressId={baseAddressId}
                            addressRoad={
                              type === "sender"
                                ? delivery.senderAddressRoad
                                : delivery.receiverAddressRoad
                            }
                            buildingDongNumber={
                              type === "sender"
                                ? delivery.senderBuildingDongNumber
                                : delivery.receiverBuildingDongNumber
                            }
                            onClickEdit={handleClickEdit}
                            onAfterUpdate={onAfterUpdate}
                          />
                        );
                      })}
                    </BaseAddressGroup>
                  ),
                )}
              </>
            )}
            {baseAddressesWithBuildingDongWithAddressRoad.length > 0 && (
              <>
                {Object.entries(
                  baseAddressesWithBuildingDongWithAddressRoadGroupBy,
                ).map(([key, value], index) => (
                  <BaseAddressGroup
                    key={
                      "baseAddressesWithBuildingDongWithAddressRoadGroupBy" +
                      index
                    }
                  >
                    <BaseAddressGroupTitle>{key}동 정보</BaseAddressGroupTitle>
                    {value.map((el) => {
                      return (
                        <BaseAddress
                          key={el.id}
                          baseAddressMemo={el}
                          baseAddressId={baseAddressId}
                          addressRoad={
                            type === "sender"
                              ? delivery.senderAddressRoad
                              : delivery.receiverAddressRoad
                          }
                          buildingDongNumber={
                            type === "sender"
                              ? delivery.senderBuildingDongNumber
                              : delivery.receiverBuildingDongNumber
                          }
                          onClickEdit={handleClickEdit}
                          onAfterUpdate={onAfterUpdate}
                        />
                      );
                    })}
                  </BaseAddressGroup>
                ))}
              </>
            )}

            {baseAddressesNoBuildingDongWithAddressRoad.length > 0 ? (
              <>
                <BaseAddressGroupTitle>
                  {type === "sender"
                    ? delivery.senderAddressRoad
                    : delivery.receiverAddressRoad}
                </BaseAddressGroupTitle>
                {baseAddressesNoBuildingDongWithAddressRoad.map((el) => {
                  return (
                    <BaseAddress
                      key={el.id}
                      baseAddressMemo={el}
                      baseAddressId={baseAddressId}
                      addressRoad={
                        type === "sender"
                          ? delivery.senderAddressRoad
                          : delivery.receiverAddressRoad
                      }
                      buildingDongNumber={
                        type === "sender"
                          ? delivery.senderBuildingDongNumber
                          : delivery.receiverBuildingDongNumber
                      }
                      onClickEdit={handleClickEdit}
                      onAfterUpdate={onAfterUpdate}
                    />
                  );
                })}
              </>
            ) : (
              <></>
            )}

            {baseAddressesWithBuildingDongWithAddress.length > 0 && (
              <>
                {Object.entries(
                  baseAddressesWithBuildingDongWithAddressGroupBy,
                ).map(([key, value], index) => (
                  <BaseAddressGroup
                    key={
                      "baseAddressesWithBuildingDongWithAddressGroupBy" + index
                    }
                  >
                    <BaseAddressGroupTitle>
                      {value[0]?.baseAddress.address}
                    </BaseAddressGroupTitle>
                    <BaseAddressGroupTitle>{key}동 정보</BaseAddressGroupTitle>
                    {value.map((el) => {
                      return (
                        <BaseAddress
                          key={el.id}
                          baseAddressMemo={el}
                          baseAddressId={baseAddressId}
                          addressRoad={
                            type === "sender"
                              ? delivery.senderAddressRoad
                              : delivery.receiverAddressRoad
                          }
                          buildingDongNumber={
                            type === "sender"
                              ? delivery.senderBuildingDongNumber
                              : delivery.receiverBuildingDongNumber
                          }
                          onClickEdit={handleClickEdit}
                          onAfterUpdate={onAfterUpdate}
                        />
                      );
                    })}
                  </BaseAddressGroup>
                ))}
              </>
            )}

            {baseAddressesNoBuildingDongWithAddress.length > 0 ? (
              <>
                {Object.entries(
                  baseAddressesNoBuildingDongWithAddressGroupBy,
                ).map(([key, value], index) => (
                  <BaseAddressGroup
                    key={
                      "baseAddressesNoBuildingDongWithAddressGroupBy" + index
                    }
                  >
                    <BaseAddressGroupTitle>{key}</BaseAddressGroupTitle>
                    {value.map((el) => {
                      return (
                        <BaseAddress
                          key={el.id}
                          baseAddressMemo={el}
                          baseAddressId={baseAddressId}
                          addressRoad={
                            type === "sender"
                              ? delivery.senderAddressRoad
                              : delivery.receiverAddressRoad
                          }
                          buildingDongNumber={
                            type === "sender"
                              ? delivery.senderBuildingDongNumber
                              : delivery.receiverBuildingDongNumber
                          }
                          onClickEdit={handleClickEdit}
                          onAfterUpdate={onAfterUpdate}
                        />
                      );
                    })}
                  </BaseAddressGroup>
                ))}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {baseAddressesNoBuildingDongWithAddressRoad.length > 0 ? (
              <>
                <BaseAddressGroupTitle>
                  {type === "sender"
                    ? delivery.senderAddressRoad
                    : delivery.receiverAddressRoad}
                </BaseAddressGroupTitle>
                {baseAddressesNoBuildingDongWithAddressRoad.map((el) => {
                  return (
                    <BaseAddress
                      key={el.id}
                      baseAddressMemo={el}
                      baseAddressId={baseAddressId}
                      addressRoad={
                        type === "sender"
                          ? delivery.senderAddressRoad
                          : delivery.receiverAddressRoad
                      }
                      buildingDongNumber={
                        type === "sender"
                          ? delivery.senderBuildingDongNumber
                          : delivery.receiverBuildingDongNumber
                      }
                      onClickEdit={handleClickEdit}
                      onAfterUpdate={onAfterUpdate}
                    />
                  );
                })}
              </>
            ) : (
              <></>
            )}
            {baseAddressesWithBuildingDongWithAddressRoad.length > 0 && (
              <>
                {Object.entries(
                  baseAddressesWithBuildingDongWithAddressRoadGroupBy,
                ).map(([key, value], index) => (
                  <BaseAddressGroup
                    key={
                      "baseAddressesWithBuildingDongWithAddressRoadGroupBy" +
                      index
                    }
                  >
                    <BaseAddressGroupTitle>{key}동 정보</BaseAddressGroupTitle>
                    {value.map((el) => {
                      return (
                        <BaseAddress
                          key={el.id}
                          baseAddressMemo={el}
                          baseAddressId={baseAddressId}
                          addressRoad={
                            type === "sender"
                              ? delivery.senderAddressRoad
                              : delivery.receiverAddressRoad
                          }
                          buildingDongNumber={
                            type === "sender"
                              ? delivery.senderBuildingDongNumber
                              : delivery.receiverBuildingDongNumber
                          }
                          onClickEdit={handleClickEdit}
                          onAfterUpdate={onAfterUpdate}
                        />
                      );
                    })}
                  </BaseAddressGroup>
                ))}
              </>
            )}
            {baseAddressesNoBuildingDongWithAddress.length > 0 ? (
              <>
                {Object.entries(
                  baseAddressesNoBuildingDongWithAddressGroupBy,
                ).map(([key, value], index) => (
                  <BaseAddressGroup
                    key={
                      "baseAddressesNoBuildingDongWithAddressGroupBy" + index
                    }
                  >
                    <BaseAddressGroupTitle>{key}</BaseAddressGroupTitle>
                    {value.map((el) => {
                      return (
                        <BaseAddress
                          key={el.id}
                          baseAddressMemo={el}
                          baseAddressId={baseAddressId}
                          addressRoad={
                            type === "sender"
                              ? delivery.senderAddressRoad
                              : delivery.receiverAddressRoad
                          }
                          buildingDongNumber={
                            type === "sender"
                              ? delivery.senderBuildingDongNumber
                              : delivery.receiverBuildingDongNumber
                          }
                          onClickEdit={handleClickEdit}
                          onAfterUpdate={onAfterUpdate}
                        />
                      );
                    })}
                  </BaseAddressGroup>
                ))}
              </>
            ) : (
              <></>
            )}
            {baseAddressesWithBuildingDongWithAddress.length > 0 && (
              <>
                {Object.entries(
                  baseAddressesWithBuildingDongWithAddressGroupBy,
                ).map(([key, value], index) => (
                  <BaseAddressGroup
                    key={
                      "baseAddressesWithBuildingDongWithAddressGroupBy" + index
                    }
                  >
                    <BaseAddressGroupTitle>
                      {value[0]?.baseAddress.address}
                    </BaseAddressGroupTitle>
                    <BaseAddressGroupTitle>{key}동 정보</BaseAddressGroupTitle>
                    {value.map((el) => {
                      return (
                        <BaseAddress
                          key={el.id}
                          baseAddressMemo={el}
                          baseAddressId={baseAddressId}
                          addressRoad={
                            type === "sender"
                              ? delivery.senderAddressRoad
                              : delivery.receiverAddressRoad
                          }
                          buildingDongNumber={
                            type === "sender"
                              ? delivery.senderBuildingDongNumber
                              : delivery.receiverBuildingDongNumber
                          }
                          onClickEdit={handleClickEdit}
                          onAfterUpdate={onAfterUpdate}
                        />
                      );
                    })}
                  </BaseAddressGroup>
                ))}
              </>
            )}
          </>
        )}

        {!baseAddresses.length ? (
          <BaseAddressWrap>
            <BaseAddressContent>
              {fetched
                ? "등록된 주소 정보가 없습니다."
                : "주소정보를 불러오고 있습니다."}
            </BaseAddressContent>
          </BaseAddressWrap>
        ) : (
          <></>
        )}
      </BaseAddressesWrap>
    </Container>
  );
};

export default BaseAddresses;
