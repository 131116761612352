const params = new URLSearchParams(window.location.search);
export const appVersion = params.get("appVersion");

const parseObjToBodyString = (obj) => {
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");
};

export default {
  get: (endpoint, riderToken, query = {}) => {
    let isJson = true;

    return fetch(
      `${process.env.REACT_APP_API_HOST_NEW}/${endpoint}?${parseObjToBodyString(
        query,
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${riderToken}`,
          "x-version": process.env.REACT_APP_VERSION_TYPE,
          "X-App-Version": appVersion,
        },
      },
    )
      .then((res) => {
        const contentType = res.headers.get("content-type");

        if (contentType.includes("openxmlformats")) {
          isJson = false;
          return res.blob();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        if (!isJson) {
          return res;
        }

        if (res.statusCode >= 300) {
          const error = new Error();
          error.status = res.statusCode;
          error.statusCode = res.statusCode;
          error.message = res.error || res.message;
          throw error;
        }

        return res;
      });
  },

  put: (endpoint, riderToken, body = {}) => {
    return fetch(`${process.env.REACT_APP_API_HOST_NEW}/${endpoint}`, {
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${riderToken}`,
        "x-version": process.env.REACT_APP_VERSION_TYPE,
        "X-App-Version": appVersion,
      },
      method: "PUT",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode >= 300) {
          const error = new Error();
          error.status = res.statusCode;
          error.statusCode = res.statusCode;
          error.message = res.error || res.message;
          throw error;
        }

        return res;
      });
  },

  post: (endpoint, riderToken, body = {}) => {
    return fetch(`${process.env.REACT_APP_API_HOST_NEW}/${endpoint}`, {
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${riderToken}`,
        "x-version": process.env.REACT_APP_VERSION_TYPE,
        "X-App-Version": appVersion,
      },
      method: "POST",
    })
      .then((res) => {
        const contentType = res.headers.get("content-type");

        if (contentType.includes("text")) {
          return res.blob();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        if (res.statusCode >= 300) {
          const error = new Error();
          error.status = res.statusCode;
          error.statusCode = res.statusCode;
          error.message = res.error || res.message;
          throw error;
        }

        return res;
      });
  },

  delete: (endpoint, riderToken, body = {}) => {
    return fetch(`${process.env.REACT_APP_API_HOST_NEW}/${endpoint}`, {
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${riderToken}`,
        "x-version": process.env.REACT_APP_VERSION_TYPE,
        "X-App-Version": appVersion,
      },
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode >= 300) {
          const error = new Error();
          error.status = res.statusCode;
          error.statusCode = res.statusCode;
          error.message = res.error || res.message;
          throw error;
        }

        return res;
      });
  },
};
