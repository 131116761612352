import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import api from "services/Api";

import { riderTokenNew } from "utils/params";

export const RidersContext = createContext({});

const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_FAVO_RIDERS: "SET_FAVO_RIDERS",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_STATE = {
  riders: [],
  favoRiders: [],
  query: {
    page: 1,
    pageSize: 20,
    pageCount: 1,
    text: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        riders: action.riders,
        query: {
          ...state.query,
          pageCount: action.pageCount,
        },
      };
    case CONSTANTS.SET_FAVO_RIDERS:
      return {
        ...state,
        favoRiders: action.favoRiders,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const RidersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <RidersContext.Provider value={{ state, dispatch }}>
      {children}
    </RidersContext.Provider>
  );
};

export const useRidersStore = () => {
  const { dispatch, state } = useContext(RidersContext);

  /**
   * 라이더 목록
   */
  const fetchAll = useCallback(
    async (_page) => {
      let page = _page || state.query.page;

      if (riderTokenNew) {
        const response = await api.get(`riders`, riderTokenNew, {
          page,
          pageSize: state.query.pageSize,
          text: state.query.text,
          sort: "name",
        });

        dispatch({
          type: CONSTANTS.FETCH_ALL,
          riders:
            page === 1 ? response.rows : state.riders.concat(response.rows),
          pageCount: response.pageCount,
        });
      } else {
        dispatch({
          type: CONSTANTS.FETCH_ALL,
          riders: [],
          pageCount: 0,
        });
      }
    },
    [dispatch, state.riders, state.query],
  );

  /**
   * 즐겨찾기 라이더 목록
   */
  const setFavoRiders = useCallback(
    (favoRiders) => {
      dispatch({
        type: CONSTANTS.SET_FAVO_RIDERS,
        favoRiders,
      });
    },
    [dispatch],
  );

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  return {
    state,
    fetchAll,
    setFavoRiders,
    setQuery,
  };
};
