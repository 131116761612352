import React from "react";
import { Route, Switch } from "react-router-dom";

import BaseAddresses from "./pages/BaseAddresses";
import Map from "./pages/Map";
import MapWithRoutes from "./pages/MapWithRoutes";
import MapById from "./pages/MapById";
import Postcode from "./pages/Postcode";
import NotFound from "./pages/404";

import { useModalStore } from "./stores/hooks";

const Router = () => {
  const { modals } = useModalStore().state;

  return (
    <>
      <Switch>
        <Route exact path="/map" component={Map} />
        <Route exact path="/map-routes" component={MapWithRoutes} />
        <Route exact path="/map-by-id" component={MapById} />
        <Route exact path="/postcode" component={Postcode} />
        <Route exact path="/base-addresses" component={BaseAddresses} />
        <Route component={NotFound} />
      </Switch>
      {modals.map((modal) => modal)}
    </>
  );
};

export default Router;
