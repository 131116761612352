import React from "react";
import styled from "styled-components";

const NotFoundContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const NotFoundHeader = styled.h2`
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 40px;
`;
const NotFoundDesc = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
`;

const NotFound = () => {
  return (
    <NotFoundContainer>
      <NotFoundHeader>404</NotFoundHeader>
      <NotFoundDesc>페이지 주소를 다시 확인해주세요.</NotFoundDesc>
    </NotFoundContainer>
  );
};

export default NotFound;
