import {
  DELIVERY_TYPE_NORMAL,
  // STATUS_PICKUP,
  STATUS_DELIVERY_WAITING,
  STATUS_DELIVERY_POSTPONED,
  STATUS_DELIVERY_STARTED,
} from "Models";

export const sameReceiverWaitDeliveries = (delivery, deliveries) => {
  if (
    (delivery.status === STATUS_DELIVERY_WAITING ||
      delivery.status === STATUS_DELIVERY_POSTPONED ||
      delivery.status === STATUS_DELIVERY_STARTED) &&
    deliveries &&
    deliveries.length &&
    delivery.type === DELIVERY_TYPE_NORMAL
  ) {
    return deliveries.filter(
      (d) =>
        delivery.bookId !== d.bookId &&
        delivery.type === d.type &&
        delivery.receiverName === d.receiverName &&
        delivery.receiverMobile === d.receiverMobile &&
        delivery.receiverAddress === d.receiverAddress &&
        d.status === STATUS_DELIVERY_WAITING,
    );
  } else {
    return [];
  }
};

export const sameReceiverPostponedDeliveries = (delivery, deliveries) => {
  if (
    (delivery.status === STATUS_DELIVERY_WAITING ||
      delivery.status === STATUS_DELIVERY_POSTPONED ||
      delivery.status === STATUS_DELIVERY_STARTED) &&
    deliveries &&
    deliveries.length &&
    delivery.type === DELIVERY_TYPE_NORMAL
  ) {
    return deliveries.filter(
      (d) =>
        delivery.bookId !== d.bookId &&
        delivery.type === d.type &&
        delivery.receiverName === d.receiverName &&
        delivery.receiverMobile === d.receiverMobile &&
        delivery.receiverAddress === d.receiverAddress &&
        d.status === STATUS_DELIVERY_POSTPONED,
    );
  } else {
    return [];
  }
};

export const sameReceiverStartedDeliveries = (delivery, deliveries) => {
  if (
    (delivery.status === STATUS_DELIVERY_WAITING ||
      delivery.status === STATUS_DELIVERY_POSTPONED ||
      delivery.status === STATUS_DELIVERY_STARTED) &&
    deliveries &&
    deliveries.length &&
    delivery.type === DELIVERY_TYPE_NORMAL
  ) {
    return deliveries.filter(
      (d) =>
        delivery.bookId !== d.bookId &&
        delivery.type === d.type &&
        delivery.receiverName === d.receiverName &&
        delivery.receiverMobile === d.receiverMobile &&
        delivery.receiverAddress === d.receiverAddress &&
        d.status === STATUS_DELIVERY_STARTED,
    );
  } else {
    return [];
  }
};

export const checkNearbyMarkers = (marker, markers) => {
  let nearbyMarkersArr = [];
  let nearbyMarkersInOtherStatusArr = [];

  if (markers && markers.length) {
    markers.forEach((d) => {
      if (
        marker.bookId !== d.bookId &&
        ((d.longitude - marker.longitude >= 0 &&
          d.longitude - marker.longitude <= 0.0002) ||
          (marker.longitude - d.longitude >= 0 &&
            marker.longitude - d.longitude <= 0.0002)) &&
        ((d.latitude - marker.latitude >= 0 &&
          d.latitude - marker.latitude <= 0.0002) ||
          (marker.latitude - d.latitude >= 0 &&
            marker.latitude - d.latitude <= 0.0002))
      ) {
        if (d.status === marker.status) {
          nearbyMarkersArr.push(d);
        } else {
          nearbyMarkersInOtherStatusArr.push(d);
        }
      }
    });
  }

  return { nearbyMarkersArr, nearbyMarkersInOtherStatusArr };
};

// export const nearbyMarkers = (marker, markers) => {
//   if (marker.status === STATUS_PICKUP && markers && markers.length) {
//     return markers.filter((d) =>
//       marker.bookId !== d.bookId
//       && (
//         (d.longitude - marker.longitude >= 0 && d.longitude - marker.longitude <= 0.0002)
//         || (marker.longitude - d.longitude >= 0 && marker.longitude - d.longitude <= 0.0002)
//       )
//       && (
//         (d.latitude - marker.latitude >= 0 && d.latitude - marker.latitude <= 0.0002)
//         || (marker.latitude - d.latitude >= 0 && marker.latitude - d.latitude <= 0.0002)
//       )
//       && d.status === STATUS_PICKUP
//     )
//   } else if (marker.status === STATUS_DELIVERY_STARTED && markers && markers.length) {
//     return markers.filter((d) =>
//       marker.bookId !== d.bookId
//       && (
//         (d.longitude - marker.longitude >= 0 && d.longitude - marker.longitude <= 0.0002)
//         || (marker.longitude - d.longitude >= 0 && marker.longitude - d.longitude <= 0.0002)
//       )
//       && (
//         (d.latitude - marker.latitude >= 0 && d.latitude - marker.latitude <= 0.0002)
//         || (marker.latitude - d.latitude >= 0 && marker.latitude - d.latitude <= 0.0002)
//       )
//       && d.status === STATUS_DELIVERY_STARTED
//     )
//   } else if (marker.status === STATUS_DELIVERY_WAITING && markers && markers.length) {
//     return markers.filter((d) =>
//       marker.bookId !== d.bookId
//       && (
//         (d.longitude - marker.longitude >= 0 && d.longitude - marker.longitude <= 0.0002)
//         || (marker.longitude - d.longitude >= 0 && marker.longitude - d.longitude <= 0.0002)
//       )
//       && (
//         (d.latitude - marker.latitude >= 0 && d.latitude - marker.latitude <= 0.0002)
//         || (marker.latitude - d.latitude >= 0 && marker.latitude - d.latitude <= 0.0002)
//       )
//       && d.status === STATUS_DELIVERY_WAITING
//     )
//   } else {
//     return [];
//   }
// };

// export const nearbyOtherStatusMarkers = (marker, markers) => {
//   if (marker.status === STATUS_PICKUP && markers && markers.length) {
//     return markers.filter((d) =>
//       marker.bookId !== d.bookId
//       && (
//         (d.longitude - marker.longitude >= 0 && d.longitude - marker.longitude <= 0.0002)
//         || (marker.longitude - d.longitude >= 0 && marker.longitude - d.longitude <= 0.0002)
//       )
//       && (
//         (d.latitude - marker.latitude >= 0 && d.latitude - marker.latitude <= 0.0002)
//         || (marker.latitude - d.latitude >= 0 && marker.latitude - d.latitude <= 0.0002)
//       )
//       && d.status !== STATUS_PICKUP
//     )
//   } else if (marker.status === STATUS_DELIVERY_STARTED && markers && markers.length) {
//     return markers.filter((d) =>
//       marker.bookId !== d.bookId
//       && (
//         (d.longitude - marker.longitude >= 0 && d.longitude - marker.longitude <= 0.0002)
//         || (marker.longitude - d.longitude >= 0 && marker.longitude - d.longitude <= 0.0002)
//       )
//       && (
//         (d.latitude - marker.latitude >= 0 && d.latitude - marker.latitude <= 0.0002)
//         || (marker.latitude - d.latitude >= 0 && marker.latitude - d.latitude <= 0.0002)
//       )
//       && d.status !== STATUS_DELIVERY_STARTED
//     )
//   } else if (marker.status === STATUS_DELIVERY_WAITING && markers && markers.length) {
//     return markers.filter((d) =>
//       marker.bookId !== d.bookId
//       && (
//         (d.longitude - marker.longitude >= 0 && d.longitude - marker.longitude <= 0.0002)
//         || (marker.longitude - d.longitude >= 0 && marker.longitude - d.longitude <= 0.0002)
//       )
//       && (
//         (d.latitude - marker.latitude >= 0 && d.latitude - marker.latitude <= 0.0002)
//         || (marker.latitude - d.latitude >= 0 && marker.latitude - d.latitude <= 0.0002)
//       )
//       && d.status !== STATUS_DELIVERY_WAITING
//     )
//   } else {
//     return [];
//   }
// };
