import React, { useEffect, useCallback } from "react";
import styled, { ThemeProvider } from "styled-components";

import {
  PICKUP,
  DELIVER,
  COMPLETED,
  COMPLETED_WITH_LOCATION,
} from "constants/category";
import { MESSAGE_UPDATED } from "constants/webview";
import { SIZE } from "constants/themes";

import { useCommonStore, useDeliveryStore, useMapStore } from "stores/hooks";

import { ADDRESS_API_TYPE_KAKAO, STATUS_PICKUP } from "Models";

import { initMap } from "utils/map";
import { params, appVersion } from "utils/params";

const Container = styled.div`
  .info-title {
    font-weight: bold;
    font-size: 16px;
    display: flex;
  }

  .info-spot {
    font-weight: bold;
    font-size: 14px;
  }

  .info-address {
    color: #828182;
    font-size: 14px;
  }

  .marker-address {
    font-size: ${({ theme }) => theme.font10}px;
    position: absolute;
    width: 80px;
    text-align: center;
    top: 40px;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const CurrentPosition = styled.div`
  background-image: url(/assets/images/map/icon-position.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 1000;
  right: 10px;
  top: 10px;

  ${({ active }) =>
    active && `background-image: url(/assets/images/map/icon-position-on.png);`}
`;

const MapConatiner = styled.div`
  width: 100%;
  height: 100vh;
`;

const MapById = () => {
  const { size } = useCommonStore().state;

  const { delivery } = useDeliveryStore().state;
  const { ...deliveryActions } = useDeliveryStore();

  const { map, currentCenter } = useMapStore().state;
  const { ...mapActions } = useMapStore();

  const id = params.get("id");
  const category = params.get("category");
  const latitude = params.get("latitude");
  const longitude = params.get("longitude");

  const updatePostion = (e) => {
    if (e.data) {
      mapActions.updateCurrentPostion(JSON.parse(e.data));
    }
  };

  useEffect(() => {
    initMap(() => {
      mapActions.drawMap({ zoom: 16 });
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener("message", updatePostion);
    }, 200);

    return () => {
      document.removeEventListener("message", updatePostion);
    };
  }, [updatePostion]); // completedWithLocation

  const drawMarker = useCallback(() => {
    if (category === COMPLETED.key && latitude && longitude) {
      delivery.latitude = latitude;
      delivery.longitude = longitude;
    } else if (
      category === COMPLETED_WITH_LOCATION.key &&
      latitude &&
      longitude
    ) {
      delivery.statusText = DELIVER.key;
      delivery.longitude = delivery.compareReceiverLocation.longitude;
      delivery.latitude = delivery.compareReceiverLocation.latitude;
      delivery.completedLatitude = latitude;
      delivery.completedLongitude = longitude;
    } else if (category === PICKUP.key) {
      delivery.statusText = PICKUP.key;
      delivery.longitude = delivery.compareSenderLocation.longitude;
      delivery.latitude = delivery.compareSenderLocation.latitude;
    } else {
      delivery.statusText = DELIVER.key;
      delivery.longitude = delivery.compareReceiverLocation.longitude;
      delivery.latitude = delivery.compareReceiverLocation.latitude;
    }

    map.setCenter(
      new window.naver.maps.LatLng(delivery.latitude, delivery.longitude),
    );

    let marker = new window.naver.maps.Marker({
      map: map,
      position: new window.naver.maps.LatLng(
        delivery.latitude,
        delivery.longitude,
      ),
      title: delivery.bookId,
      icon: {
        size: new window.naver.maps.Size(23, 32),
        scaledSize: new window.naver.maps.Size(23, 32),
        content: `<div class="marker ${delivery.statusText} ${
          delivery.keyColor
        }">
            <div class="marker-address">
            ${
              category === PICKUP.key
                ? `${
                    delivery.senderAddressRoadWithoutSigungu ||
                    delivery.senderAddressWithoutSigungu
                  }${
                    delivery.senderAddressObj &&
                    delivery.senderAddressObj.building
                      ? `<br/>${delivery.senderAddressObj.building}`
                      : ""
                  }`
                : `${
                    delivery.receiverAddressRoadWithoutSigungu ||
                    delivery.receiverAddressWithoutSigungu
                  }${
                    delivery.receiverAddressObj &&
                    delivery.receiverAddressObj.building
                      ? `<br/>${delivery.receiverAddressObj.building}`
                      : ""
                  }`
            }
            </div>
          </div>`,
      },
      zIndex: 100,
    });

    if (category === COMPLETED_WITH_LOCATION.key) {
      new window.naver.maps.Marker({
        map: map,
        position: new window.naver.maps.LatLng(
          delivery.completedLatitude,
          delivery.completedLongitude,
        ),
        title: delivery.bookId,
        icon: {
          size: new window.naver.maps.Size(23, 32),
          scaledSize: new window.naver.maps.Size(23, 32),
          content: `<div class="marker completed"></div>`,
        },
        zIndex: 100,
      });
    }

    if (category !== COMPLETED.key) {
      let infoWindow = new window.naver.maps.InfoWindow({
        content: `
          <div class="info" style="padding: 10px; max-width: 250px">
            <div class="info-title ${delivery.statusText}">
              <span style="color: ${delivery.keyColorHex}">(${
          delivery.statusString
        })</span>&nbsp;
              ${delivery.bookId}
              <span style="color: ${delivery.keyColorHex}">${
          delivery.typeString
        }</span>
            </div>
            <div class="info-address">
              ${
                delivery.status !== STATUS_PICKUP
                  ? delivery.receiverAddressWithoutSigungu
                    ? `${delivery.receiverAddressWithoutSigungu}${
                        !delivery.addressApiType ||
                        delivery.addressApiType === ADDRESS_API_TYPE_KAKAO
                          ? "/확인필요"
                          : ""
                      }`
                    : `${delivery.receiverSigungu} ${
                        delivery.receiverDong || ""
                      }${
                        !delivery.addressApiType ||
                        delivery.addressApiType === ADDRESS_API_TYPE_KAKAO
                          ? "/확인필요"
                          : ""
                      }`
                  : ""
              }
            </div>
            <div class="info-address">
              (출) ${
                delivery.senderAddress || delivery.senderAddressRoad || ""
              } ${delivery.senderAddressDetail}
              <br/>
              (도) ${delivery.displayAddress} ${
          delivery.memoFromCustomer
            ? `<span class="c-red">(${delivery.memoFromCustomer})</span>`
            : ""
        }
            </div>
          </div>
        `,
      });

      window.naver.maps.Event.addListener(marker, "click", getClickHandler());

      window.naver.maps.Event.addListener(map, "click", function () {
        if (infoWindow.getMap()) {
          infoWindow.close();
        }
      });

      function getClickHandler() {
        return function () {
          if (infoWindow.getMap()) {
            infoWindow.close();
          } else {
            infoWindow.open(map, marker);
          }
        };
      }
    }

    if (
      delivery.soojioneLimitedCustomized &&
      delivery.soojioneLimitedCustomizedLngLat &&
      delivery.status !== STATUS_PICKUP &&
      delivery.statusAddressNotSupported !== STATUS_PICKUP &&
      category === DELIVER.key
    ) {
      let markerSoojiwonIcon = {
        size: new window.naver.maps.Size(23, 32),
        scaledSize: new window.naver.maps.Size(23, 32),
        content: `
            <div class="marker soojione">
              ${
                delivery.soojioneLimitedCustomizedAddressRoadWithoutSigungu
                  ? `<div class="marker-address">
                  ${delivery.soojioneLimitedCustomizedAddressRoadWithoutSigungu}</div>`
                  : ""
              }
            </div>
          `,
      };

      new window.naver.maps.Marker({
        map: map,
        position: new window.naver.maps.LatLng(
          delivery.soojioneLimitedCustomizedLngLat?.latitude,
          delivery.soojioneLimitedCustomizedLngLat?.longitude,
        ),
        title: "SOOJIWON",
        icon: markerSoojiwonIcon,
        zIndex: 100,
      });

      let polylinePath = [
        new window.naver.maps.LatLng(delivery.latitude, delivery.longitude),
        new window.naver.maps.LatLng(
          delivery.soojioneLimitedCustomizedLngLat?.latitude,
          delivery.soojioneLimitedCustomizedLngLat?.longitude,
        ),
      ];

      //위의 배열을 이용해 라인 그리기
      new window.naver.maps.Polyline({
        path: polylinePath, //선 위치 변수배열
        strokeColor: "#FF0000", //선 색 빨강 #빨강,초록,파랑
        strokeOpacity: 0.8, //선 투명도 0 ~ 1
        strokeWeight: 4, //선 두께
        strokeStyle: "shortdash",
        map: map, //오버레이할 지도
      });
    }

    if (window.ReactNativeWebView) {
      if (appVersion) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: MESSAGE_UPDATED }),
        );
      } else {
        window.ReactNativeWebView.postMessage(MESSAGE_UPDATED);
      }
    }
  }, [delivery, map, category, latitude, longitude, appVersion]);

  useEffect(() => {
    if (map && delivery) {
      drawMarker();
    }
  }, [drawMarker, map, delivery]);

  useEffect(() => {
    fetchById();
  }, [id]);

  // 배송 조회
  const fetchById = useCallback(async () => {
    try {
      await deliveryActions.fetchById({
        bookId: id,
      });
    } catch (e) {
      alert(`배송 상세를 가져올 수 없습니다. \n에러메시지: ${e.message}`);
    }
  }, [id, deliveryActions.fetchById]);

  // 현재 위치 표시
  const getCurrentPosition = useCallback(() => {
    mapActions.getCurrentPosition(JSON.stringify(!currentCenter));
  }, [mapActions.getCurrentPosition, currentCenter]);

  return (
    <ThemeProvider theme={size ? SIZE[size] : {}}>
      <Container>
        <CurrentPosition onClick={getCurrentPosition} active={currentCenter} />
        <MapConatiner id="map" />
      </Container>
    </ThemeProvider>
  );
};

export default MapById;
