export const TMAP = "TMAP";
export const KAKAO = "KAKAO";
export const NAVER = "NAVER";

export const getLinkText = (link) => {
  if (link === TMAP) {
    return "티맵";
  } else if (link === KAKAO) {
    return "카카오";
  } else if (link === NAVER) {
    return "네이버";
  } else {
    return "";
  }
}