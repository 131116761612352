import React from "react";
import styled from "styled-components";

import { useRoutesStore } from "stores/hooks";
import { addSeconds, hhmmString } from "utils/date";

const Self = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 50px;
  background-color: rgba(255, 255, 255, 0.4);
`;

const Text = styled.div`
  padding: 12px;
  font-size: 10px;
  font-weight: bold;
`;

const Highlight = styled.span`
  color: red;
`;

const RouteInfo = () => {
  const { route, routesViaStops, routesViaStopsAt, deliveryNext } =
    useRoutesStore().state;

  if (!route || !deliveryNext) {
    return null;
  }

  let totlaHours = 0;
  let totalMinutes = 0;
  let totalSeconds = 0;

  if (routesViaStops.totalTime) {
    totlaHours = parseInt(routesViaStops.totalTime / 3600);
    const _totalMinutes = parseInt(routesViaStops.totalTime / 60);
    totalMinutes = _totalMinutes > 59 ? _totalMinutes - 60 : _totalMinutes;
    totalSeconds = parseInt(routesViaStops.totalTime % 60);
  }

  let leftHours = 0;
  let leftMinutes = 0;
  let leftSeconds = 0;

  if (route.totalTime) {
    leftHours = parseInt(route.totalTime / 3600);
    const _leftMinutes = parseInt(route.totalTime / 60);
    leftMinutes = _leftMinutes > 59 ? _leftMinutes - 60 : _leftMinutes;
    leftSeconds = parseInt(route.totalTime % 60);
  }

  let leftKmeter = 0;
  let leftMeter = 0;

  if (route.totalDistance) {
    leftKmeter = parseInt(route.totalDistance / 1000);
    leftMeter = parseInt(route.totalDistance % 1000);
  }

  return (
    <Self>
      <Text>
        [전체배송시간 {totlaHours ? `${totlaHours}시간 ` : ""}
        {totalMinutes ? `${totalMinutes}분 ` : ""}
        {totalSeconds ? `${totalSeconds}초 ` : ""} / 완료예상{" "}
        {addSeconds(routesViaStopsAt, routesViaStops.totalTime || 0)} ]
        <br />
        (순서 {deliveryNext?.order} 거리 {leftKmeter ? `${leftKmeter}km ` : ""}
        {leftMeter ? `${leftMeter}m` : ""}, 시간{" "}
        {leftHours ? `${leftHours}시간 ` : ""}
        {leftMinutes ? `${leftMinutes}분 ` : ""}
        {leftSeconds ? `${leftSeconds}초 ` : ""})
        {deliveryNext.isLimitedHours && (
          <Highlight>
            (도착보장 {hhmmString(deliveryNext.limitedDeliveryAt)}){" "}
          </Highlight>
        )}
        {deliveryNext.isHighPrice && <Highlight>(고가품)</Highlight>}
        <br />
        다음 배송은{" "}
        <Highlight>
          [{deliveryNext.renderSenderName}({deliveryNext.renderReceiverName})] [
          {deliveryNext.displayAddress}]
        </Highlight>
        입니다.{" "}
        {deliveryNext.frontdoorPassword ? (
          <>
            현관비밀번호는{" "}
            <Highlight>[{deliveryNext.frontdoorPassword}]</Highlight> 입니다.
          </>
        ) : (
          <></>
        )}
        {deliveryNext.deliveryPlace && (
          <>
            <br />
            고객이 <Highlight>[경비실보관]</Highlight> 으로 요청했습니다.
          </>
        )}
      </Text>
    </Self>
  );
};

export default RouteInfo;
