import { format } from "date-fns-tz";
import dfAddDays from "date-fns/addDays";
import dfAddSeconds from "date-fns/addSeconds";

export const yymmddWithDash = (date) => {
  if (!date) {
    return "";
  }

  const _date = new Date(date);
  /// const seoulDate = utcToZonedTime(date, 'Asia/Seoul');

  return format(_date, "yyyy-MM-dd");
};

export const addDays = (date, days, dateFormat = "yyyy-MM-dd") => {
  const _date = dfAddDays(new Date(date), days);

  return format(_date, dateFormat);
};

export const addSeconds = (date, seconds, dateFormat = "HH시 mm분 ss초") => {
  const _date = dfAddSeconds(new Date(date), seconds);

  return format(_date, dateFormat);
};

export const hhmmString = (date) => {
  if (!date) {
    return "";
  }

  const _date = new Date(date);

  return format(_date, "HH시 mm분");
};
