import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

export const CONSTANTS = {
  CLOSE_MODAL: "CLOSE_MODAL",
  OPEN_MODAL: "OPEN_MODAL",
};

const INITIAL_STATE = {
  modals: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.CLOSE_MODAL:
      return {
        ...state,
        modals: [...state.modals.slice(0, -1)],
      };
    case CONSTANTS.OPEN_MODAL:
      return {
        ...state,
        modals: [...state.modals, action.component],
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useModalStore = () => {
  const { dispatch, state } = useContext(Context);

  const openModal = useCallback(
    (component) => {
      dispatch({ type: CONSTANTS.OPEN_MODAL, component });
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    dispatch({ type: CONSTANTS.CLOSE_MODAL });
  }, []);

  return {
    state,
    closeModal,
    openModal,
  };
};
