/** font size */
export const SIZE = {
  medium: {
    key: 1,
    font10: 10,
    font14: 14,
    font16: 16,
    font18: 18,
    font20: 20,
    width90: 90,
  },
  large: {
    key: 2,
    font10: 12,
    font14: 16,
    font16: 18,
    font18: 20,
    font20: 22,
    width90: 95,
  },
  xlarge: {
    key: 3,
    font10: 14,
    font14: 18,
    font16: 20,
    font18: 22,
    font20: 24,
    width90: 100,
  },
};

export const SIZE_MEDIUM = "medium";
export const SIZE_LARGE = "large";
export const SIZE_XLARGE = "xlarge";
