import React from "react";
import styled from "styled-components";

const Self = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1000;
  left: 0;
  bottom: 0;
  background-color: rgba(32, 32, 32, 0.8);
  color: #fff;
  display: none;

  ${({ isShow }) => isShow && `display: block;`}
`;

const GuideText = styled.div`
  padding: 12px 0;
  font-size: ${({ theme }) => theme.font16}px;
  text-align: center;
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const Button = styled.div`
  flex: 1;
  background-color: #75ff6f;
  color: #202020;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font18}px;
  height: 50px;

  ${({ cancel }) =>
    cancel &&
    `
      background-color: #202020;
      color: #fff;
    `}
`;

const OrderGuide = ({
  isShow,
  orderListCount,
  deliveriesCount,
  onClickSubmit,
  onClickCancel,
}) => {
  return (
    <Self isShow={isShow}>
      <GuideText>
        배송 순서대로 지도에 마커를 터치해주세요.
        <br />({orderListCount}/{deliveriesCount})
      </GuideText>
      <ButtonGroup>
        <Button onClick={onClickSubmit}>저장</Button>
        <Button cancel onClick={onClickCancel}>
          취소
        </Button>
      </ButtonGroup>
    </Self>
  );
};

export default OrderGuide;
