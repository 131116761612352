import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { toast } from "react-toastify";

import Modal from "components/Modal";

import PostalcodeModal from "modals/PostalcodeModal";

import {
  useDeliveriesStore,
  useRoutesStore,
  useOrderStore,
  useModalStore,
} from "stores/hooks";

const ModalBody = styled.div`
  padding: 0 15px;
  font-size: 16px;
  background-color: rgba(32, 32, 32, 0.8);
  border-radius: 25px;
  width: 90%;
  margin: 0 auto 16px;
  ${({ show }) => !show && `display: none;`}
`;

const ModalBodySelectGoal = styled.div`
  ${({ show }) => !show && `display: none;`}

  background-color: rgba(32, 32, 32, 0.8);
  color: #fff;
  font-size: 16px;
  border-radius: 25px;
  width: 90%;
  margin: 0 auto 16px;
  padding: 10px 20px;
`;

const ModalButton = styled.button`
  width: 100%;
  height: 53px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin: 0;

  ${({ first }) =>
    first &&
    css`
      border-bottom: 1px solid #808080;
    `}
`;
const CloseButton = styled.button`
  background-color: rgba(32, 32, 32, 0.8);
  border: none;
  color: #fff;
  width: 60px;
  height: 30px;
  border-radius: 15px;
  position: absolute;
  top: -35px;
  right: 30px;
  font-size: 12px;
  font-weight: bold;
`;

const SelectGoalTitle = styled.h5`
  text-align: center;
`;
const SelectGoalItem = styled.div`
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
`;
const SelectGoalAddress = styled.div`
  flex: 1;
`;
const SelectGoalButtonWrap = styled.div``;
const SelectGoalButton = styled.button`
  margin-left: 5px;
  background-color: #2bd723;
  border: none;
  border-radius: 9px;
  padding: 4px 10px;
  font-size: 12px;
  ${({ edit }) => edit && `background-color: #fff;`}
`;

const SelectGoalComponent = ({ title, type, finalGoal, onSelect }) => {
  const { openModal } = useModalStore();
  const { ...routesActions } = useRoutesStore();

  const handleClickEdit = useCallback(() => {
    openModal(
      <PostalcodeModal key="postalcode-modal" onSelect={handleSelectAddress} />,
    );
  }, []);

  const handleSelectAddress = useCallback(
    async (address) => {
      if (address.roadAddress || address.jibunAddress) {
        try {
          const response = await routesActions.fetchPoint({
            address: address.roadAddress || address.jibunAddress,
          });

          if (response.long && response.lat) {
            routesActions.setFinalGoals({
              type,
              data: {
                ...address,
                ...response,
              },
            });

            routesActions.fetchFinalGoals();
          } else {
            window.alert("주소 좌표값 조회에 실패했습니다. 다시 시도해주세요.");
          }
        } catch (e) {
          window.alert(
            `주소 좌표값 조회에 실패했습니다. 다시 시도해주세요. ${e.message}`,
          );
        }
      } else {
        window.alert("주소를 다시 선택해주세요.");
      }
    },
    [type],
  );

  const handleClickSelect = useCallback(() => {
    if (!finalGoal) {
      window.alert(`${title} 주소지를 입력해주세요.`);
    } else {
      onSelect(finalGoal);
    }
  }, [finalGoal]);

  return (
    <SelectGoalItem>
      <SelectGoalAddress>
        {title}: {finalGoal?.roadAddress}
      </SelectGoalAddress>
      <SelectGoalButtonWrap>
        <SelectGoalButton edit type="button" onClick={handleClickEdit}>
          수정
        </SelectGoalButton>
        <SelectGoalButton type="button" onClick={handleClickSelect}>
          선택
        </SelectGoalButton>
      </SelectGoalButtonWrap>
    </SelectGoalItem>
  );
};

function OrderAutoConfirmModal({
  startOrderManual,
  currentPosition,
  onUpdate,
}) {
  const [showSelectGoal, setShowSelectGoal] = useState(false);
  const { deliveryStartList } = useDeliveriesStore().state;

  const { ...orderActions } = useOrderStore();

  const { finalGoalCenter, finalGoalHome } = useRoutesStore().state;
  const { ...routesActions } = useRoutesStore();

  const { closeModal } = useModalStore();

  useEffect(() => {
    fetchFinalGoals();
  }, []);

  const fetchFinalGoals = useCallback(() => {
    try {
      routesActions.fetchFinalGoals();
    } catch (e) {
      console.log(e);
    }
  }, [routesActions.fetchFinalGoals]);

  const startOrderAuto = useCallback(() => {
    setShowSelectGoal(true);
  }, [deliveryStartList]);

  const handleClickHide = useCallback(() => {
    setShowSelectGoal(false);
    closeModal();
  }, [closeModal]);

  const orderAuto = useCallback(
    async (finalGoal) => {
      const start = [
        Number(currentPosition?.longitude || "127.0070008506"),
        Number(currentPosition?.latitude || "37.5316064316"),
      ];

      const goal = [
        Number(finalGoal?.long || "127.0070008506"),
        Number(finalGoal?.lat || "37.5316064316"),
      ];

      let stops = [];
      deliveryStartList.forEach((de) => {
        if (
          de.compareReceiverLocation?.longitude &&
          de.compareReceiverLocation?.latitude
        ) {
          stops.push({
            id: de.bookId,
            coordinates: [
              Number(de.compareReceiverLocation?.longitude),
              Number(de.compareReceiverLocation?.latitude),
            ],
          });
        }
      });

      if (stops.length > 1) {
        try {
          const response = await routesActions.fetchRoutesStpos({
            start,
            goal,
            stops,
          });

          submitOrderDeliveries(response?.stops);
        } catch (e) {
          window.alert(`자동 순서 지정에 실패했습니다. ${e.message}`);
        }
      } else {
        submitOrderDeliveriesWithoutApi(stops);
      }
    },
    [currentPosition, deliveryStartList, routesActions.fetchRoutesStpos],
  );

  const submitOrderDeliveries = useCallback(
    async (newOrderList) => {
      try {
        let _orderList = [];

        newOrderList.forEach((deliver) => {
          _orderList.push({
            bookId: deliver.id,
            order: deliver.index,
          });
        });

        deliveryStartList.forEach((deliver) => {
          let index = _orderList.findIndex(
            (el) => el.bookId === deliver.bookId,
          );
          if (index < 0)
            _orderList.push({
              bookId: deliver.bookId,
              order: null,
            });
        });

        const data = {
          orders: _orderList,
        };

        await orderActions.reorderDeliveries(data);

        toast(`순서 지정을 저장하였습니다.`, {
          className: "toast-background",
          bodyClassName: "toast-body",
          progressClassName: "fancy-progress-bar",
        });

        onUpdate(true);
        closeModal();
      } catch (e) {
        alert(`순서 지정에 실패하였습니다.. \n에러메시지: ${e.message}`);
      }
    },
    [orderActions.reorderDeliveries, deliveryStartList],
  );

  const submitOrderDeliveriesWithoutApi = useCallback(
    async (stops) => {
      try {
        let _orderList = [];

        stops.forEach((deliver, index) => {
          _orderList.push({
            bookId: deliver.id,
            order: index + 1,
          });
        });

        deliveryStartList.forEach((deliver) => {
          let index = _orderList.findIndex(
            (el) => el.bookId === deliver.bookId,
          );
          if (index < 0)
            _orderList.push({
              bookId: deliver.bookId,
              order: null,
            });
        });

        const data = {
          orders: _orderList,
        };

        await orderActions.reorderDeliveries(data);

        toast(`순서 지정을 저장하였습니다.`, {
          className: "toast-background",
          bodyClassName: "toast-body",
          progressClassName: "fancy-progress-bar",
        });

        onUpdate(true);
        closeModal();
      } catch (e) {
        alert(`순서 지정에 실패하였습니다.. \n에러메시지: ${e.message}`);
      }
    },
    [orderActions.reorderDeliveries, deliveryStartList],
  );

  return (
    <Modal>
      <ModalBody show={!showSelectGoal}>
        <CloseButton type="button" onClick={handleClickHide}>
          닫기
        </CloseButton>
        <ModalButton first type="button" onClick={startOrderAuto}>
          자동 순서 지정
        </ModalButton>
        <ModalButton type="button" onClick={startOrderManual}>
          수동 순서 지정
        </ModalButton>
      </ModalBody>
      <ModalBodySelectGoal show={showSelectGoal}>
        <CloseButton type="button" onClick={handleClickHide}>
          닫기
        </CloseButton>
        <SelectGoalTitle>복귀 주소 선택</SelectGoalTitle>
        <SelectGoalComponent
          title="센터"
          type="finalGoalCenter"
          finalGoal={finalGoalCenter}
          onSelect={orderAuto}
        />
        <SelectGoalComponent
          title="집"
          type="finalGoalHome"
          finalGoal={finalGoalHome}
          onSelect={orderAuto}
        />
      </ModalBodySelectGoal>
    </Modal>
  );
}

export default OrderAutoConfirmModal;
