import React from "react";
import _noop from "lodash/noop";
import OriginModal from "react-bootstrap/Modal";

import { useModalStore } from "stores/hooks";

const Modal = ({
  children,
  backdrop = false,
  onHide = _noop,
  ...restProps
}) => {
  const { closeModal } = useModalStore();

  function handleHide() {
    onHide();
    closeModal();
  }

  return (
    <OriginModal
      backdrop={backdrop}
      container={document.getElementById("root")}
      onHide={handleHide}
      show={true}
      {...restProps}
    >
      {children}
    </OriginModal>
  );
};

Modal.Header = ({ children, closeButton = true, ...restProps }) => {
  return (
    <OriginModal.Header closeButton={closeButton} {...restProps}>
      {children}
    </OriginModal.Header>
  );
};
Modal.Body = OriginModal.Body;
Modal.Footer = OriginModal.Footer;

export default Modal;
