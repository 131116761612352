import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import api from "services/Api";

import { riderTokenNew } from "utils/params";

export const RoutesContext = createContext({});

export const ROUTE_PLATFORM = {
  TMAP: "tmap",
  NCLOUD: "ncloud",
  WEMEET: "wemeet",
};

const CONSTANTS = {
  FETCH_ROUTE: "FETCH_ROUTE",
  FETCH_ROUTE_STOPS: "FETCH_ROUTE_STOPS",
  FETCH_FINAL_GOALS: "FETCH_FINAL_GOALS",
  SET_FINAL_GOALS: "SET_FINAL_GOALS",
};

const INITIAL_STATE = {
  delvieryCompleted: null,
  deliveryNext: null,
  route: null,
  tmap: null,
  ncloud: null,

  routesViaStops: null,
  routesViaStopsAt: null,

  finalGoalsCenter: null,
  finalGoalsHome: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ROUTE:
      return {
        ...state,
        delvieryCompleted: action.delvieryCompleted,
        deliveryNext: action.deliveryNext,
        route: action.route,
        tmap: action.tmap,
        ncloud: action.ncloud,
      };
    case CONSTANTS.FETCH_ROUTE_STOPS:
      return {
        ...state,
        routesViaStops: action.routesViaStops,
        routesViaStopsAt: action.routesViaStopsAt,
      };
    case CONSTANTS.FETCH_FINAL_GOALS:
      return {
        ...state,
        finalGoalCenter: action.finalGoalCenter,
        finalGoalHome: action.finalGoalHome,
      };
    default:
      return INITIAL_STATE;
  }
};

export const RoutesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <RoutesContext.Provider value={{ state, dispatch }}>
      {children}
    </RoutesContext.Provider>
  );
};

export const useRoutesStore = () => {
  const { dispatch, state } = useContext(RoutesContext);

  /**
   * 경로 조회
   */
  const fetchRoutes = useCallback(
    async ({
      delvieryCompleted,
      deliveryNext,
      start,
      goal,
      platform = ROUTE_PLATFORM.NCLOUD,
    }) => {
      if (riderTokenNew) {
        const { tmap, ncloud } = await api.post(`routes`, riderTokenNew, {
          start,
          goal,
          platform,
        });

        dispatch({
          type: CONSTANTS.FETCH_ROUTE,
          delvieryCompleted,
          deliveryNext,
          route: tmap || ncloud,
          tmap,
          ncloud,
        });
      }
    },
    [dispatch],
  );

  /**
   * 최적 경로 조회
   */
  const fetchRoutesStpos = useCallback(
    async ({ start, goal, stops, platform = ROUTE_PLATFORM.WEMEET }) => {
      if (riderTokenNew) {
        const response = await api.post(`routes/via-stops`, riderTokenNew, {
          start,
          goal,
          stops,
          platform,
        });

        await localStorage.setItem("routesViaStops", JSON.stringify(response));
        await localStorage.setItem(
          "routesViaStopsAt",
          JSON.stringify(new Date()),
        );

        dispatch({
          type: CONSTANTS.FETCH_ROUTE_STOPS,
          routesViaStops: response,
          routesViaStopsAt: new Date(),
        });

        return response;
      }
    },
    [dispatch],
  );

  /**
   * 최적 경로 조회(로컬스토리지)
   */
  const fetchRoutesStposByLocalStorage = useCallback(async () => {
    const _routesViaStops = await localStorage.getItem("routesViaStops");
    const _routesViaStopsAt = await localStorage.getItem("routesViaStopsAt");

    const routesViaStops = _routesViaStops ? JSON.parse(_routesViaStops) : null;
    const routesViaStopsAt = _routesViaStops
      ? JSON.parse(_routesViaStopsAt)
      : null;

    dispatch({
      type: CONSTANTS.FETCH_ROUTE_STOPS,
      routesViaStops,
      routesViaStopsAt,
    });
  }, [dispatch]);

  /**
   * 최종 목적지 조회
   */
  const fetchFinalGoals = useCallback(async () => {
    const _finalGoalCenter = await localStorage.getItem("finalGoalCenter");
    const _finalGoalHome = await localStorage.getItem("finalGoalHome");

    const finalGoalCenter = _finalGoalCenter
      ? JSON.parse(_finalGoalCenter)
      : null;
    const finalGoalHome = _finalGoalHome ? JSON.parse(_finalGoalHome) : null;

    dispatch({
      type: CONSTANTS.FETCH_FINAL_GOALS,
      finalGoalCenter,
      finalGoalHome,
    });
  }, [dispatch]);

  /**
   * 위경도 조회
   */
  const fetchPoint = useCallback(
    async ({ address }) => {
      return await api.get(`routes/point`, riderTokenNew, { address });
    },
    [api.get],
  );

  /**
   * 최종 목적지 변경
   */
  const setFinalGoals = useCallback(
    async ({ type, data }) => {
      await localStorage.setItem(type, JSON.stringify(data));
    },
    [dispatch],
  );

  return {
    state,
    fetchRoutes,
    fetchRoutesStpos,
    fetchRoutesStposByLocalStorage,
    fetchFinalGoals,
    fetchPoint,
    setFinalGoals,
  };
};
