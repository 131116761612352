import React from "react";
import styled from "styled-components";

import { getLinkText } from "constants/links";

import {
  STATUS_PICKUP_NORMAL,
  STATUS_PICKUP_RETURN,
  STATUS_DELIVERY_WAITING,
  STATUS_DELIVERY_POSTPONED,
  STATUS_DELIVERY_STARTED,
  STATUS_CS,
} from "Models";

const Self = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 0 12px;
  margin-bottom: 6px;
`;

const Label = styled.span``;
const Value = styled.span``;

const Status = styled.div`
  font-size: 10px;
  font-weight: bold;
  margin-right: 3px;

  ${Label} {
    margin-right: 2px;
  }
`;

const BottomSummary = ({
  statuses,
  linkOthers,
  currentCenter,
  showHideMarkers,
  showMarkerAddress,
}) => {
  let statusesString = [];

  statuses.forEach((status) => {
    switch (status) {
      case STATUS_PICKUP_NORMAL:
        statusesString.push("일반수거");
        return;
      case STATUS_PICKUP_RETURN:
        statusesString.push("픽반품수거");
        return;
      case STATUS_DELIVERY_STARTED:
        statusesString.push("배송");
        return;
      case STATUS_DELIVERY_POSTPONED:
        statusesString.push("연기");
        return;
      case STATUS_DELIVERY_WAITING:
        statusesString.push("대기");
        return;
      case STATUS_CS:
        statusesString.push("CS");
        return;
      default:
        return;
    }
  });

  return (
    <Self>
      <Status>
        <Label>마커표시</Label>
        <Value>
          {statusesString.length === 5 ? "전체" : statusesString.join(", ")}
        </Value>
      </Status>
      <Status>
        <Label>외부 맵</Label>
        <Value>{getLinkText(linkOthers)}</Value>
      </Status>
      <Status>
        <Label>위치중앙</Label>
        <Value>{currentCenter ? "ON" : "OFF"}</Value>
      </Status>
      <Status>
        <Label>외부마커</Label>
        <Value>{showHideMarkers ? "ON" : "OFF"}</Value>
      </Status>
      <Status>
        <Label>마커하단주소</Label>
        <Value>
          {showMarkerAddress === "ALL"
            ? "모두표시"
            : showMarkerAddress === "SCAN"
            ? "스캔번호표시"
            : "표시안함"}
        </Value>
      </Status>
    </Self>
  );
};

export default BottomSummary;
