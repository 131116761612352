import React, { useCallback } from "react";
import styled from "styled-components";

import { STATUS_PICKUP, STATUS_DELIVERY_WAITING, STATUS_CS } from "Models";

import { useMapStore } from "stores/hooks";

const Self = styled.div``;

// 지도 영역밖 마커
const TrackingMarkerLink = styled.button`
  z-index: 100;
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0;
  color: #2bd723;
`;

const TrackingMarker = styled.div`
  width: 36px;
  height: 36px;
  background-color: #fff;
  border: 1px solid #2bd723;
  font-size: 14px;
  position: absolute;
  z-index: 100;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ northEnd, withRoute }) =>
    northEnd && `top: ${withRoute ? "150px" : "55px"};`}
  ${({ westEnd }) => westEnd && "left: 12px;"}
  ${({ eastEnd }) => eastEnd && "right: 12px;"}
  ${({ southEnd }) => southEnd && "bottom: 80px;"}

  ${({ right }) => right > 0 && `right: ${right}%; margin-left: -18px;`}
  ${({ southEnd, top, withRoute }) =>
    !southEnd && top < 10
      ? `top: ${withRoute ? "150px" : "55px"};`
      : top >= 10
      ? `top: ${top}%; margin-top: -18px;`
      : ""}

  ${({ pickup }) =>
    pickup &&
    `
    border: 1px solid #ea2845;
    color: #ea2845;
  `}

  ${({ waiting }) =>
    waiting &&
    `
    border: 1px solid #FFD600;
    color: #FFD600;
  `}

  ${({ cs }) =>
    cs &&
    `
  border: 1px solid #000;
  color: #000;
`}
`;

// 지도외 마커
const TrackingMarkerComponent = ({ marker, mapBounds, withRoute }) => {
  const { ...mapActions } = useMapStore();

  let northEnd = false,
    southEnd = false,
    eastEnd = false,
    westEnd = false;

  let mapLngWith = mapBounds._max._lng - mapBounds._min._lng;
  let mapLatWith = mapBounds._max._lat - mapBounds._min._lat;
  let lngPercent = 0;
  let latPercent = 0;

  if (marker.position._lat > mapBounds._max._lat) {
    northEnd = true;
  } else if (marker.position._lat < mapBounds._min._lat) {
    southEnd = true;
  } else {
    latPercent =
      ((mapBounds._max._lat - marker.position._lat) / mapLatWith) * 100;
  }

  if (marker.position._lng < mapBounds._min._lng) {
    westEnd = true;
  } else if (marker.position._lng > mapBounds._max._lng) {
    eastEnd = true;
  } else {
    lngPercent =
      ((mapBounds._max._lng - marker.position._lng) / mapLngWith) * 100;
  }

  // 지도밖 영역 마커 클릭시 센터 변경
  const handleClickSetCenter = useCallback(() => {
    mapActions.setMapCenter({
      latitude: marker.position._lat,
      longitude: marker.position._lng,
    });
  }, [mapActions.setMapCenter]);

  return (
    <TrackingMarker
      northEnd={northEnd}
      southEnd={southEnd}
      eastEnd={eastEnd}
      westEnd={westEnd}
      right={Math.round(lngPercent)}
      top={Math.round(latPercent)}
      pickup={marker.status === STATUS_PICKUP}
      waiting={marker.status === STATUS_DELIVERY_WAITING}
      cs={marker.status === STATUS_CS}
      withRoute={!!withRoute}
    >
      <TrackingMarkerLink type="button" onClick={handleClickSetCenter}>
        {marker.order}
      </TrackingMarkerLink>
    </TrackingMarker>
  );
};

const TrackingMarkers = ({ markers, mapBounds, withRoute }) => {
  return (
    <Self>
      {markers.map((el, idx) => {
        // if (el.title !== "SOOJIWON") {
        return (
          <TrackingMarkerComponent
            key={idx}
            marker={el}
            mapBounds={mapBounds}
            withRoute={!!withRoute}
          />
        );
        // }
      })}
    </Self>
  );
};

export default TrackingMarkers;
