import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import api from "services/Api";

import { riderTokenNew, riderToken } from "utils/params";

export const BaseAddressesContext = createContext({});

const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  FETCH_BASE_ADDRESS_ID: "FETCH_BASE_ADDRESS_ID",
};

const INITIAL_STATE = {
  baseAddressId: null,
  baseAddresses: [],
  baseAddressesCurrentBuildingDong: [],
  baseAddressesWithBuildingDongWithAddressRoad: [],
  baseAddressesWithBuildingDongWithAddress: [],
  baseAddressesNoBuildingDongWithAddressRoad: [],
  baseAddressesNoBuildingDongWithAddress: [],
  fetched: false,
  // pageCount: 1,
  // query: {
  //   page: 1,
  //   pageSize: 100,
  // },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        baseAddresses: action.baseAddresses,
        baseAddressesCurrentBuildingDong:
          action.baseAddressesCurrentBuildingDong,
        baseAddressesWithBuildingDongWithAddressRoad:
          action.baseAddressesWithBuildingDongWithAddressRoad,
        baseAddressesWithBuildingDongWithAddress:
          action.baseAddressesWithBuildingDongWithAddress,
        baseAddressesNoBuildingDongWithAddressRoad:
          action.baseAddressesNoBuildingDongWithAddressRoad,
        baseAddressesNoBuildingDongWithAddress:
          action.baseAddressesNoBuildingDongWithAddress,
        pageCount: action.pageCount,
        fetched: true,
      };
    case CONSTANTS.FETCH_BASE_ADDRESS_ID:
      return {
        ...state,
        baseAddressId: action.baseAddressId,
      };
    default:
      return INITIAL_STATE;
  }
};

export const BaseAddressesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <BaseAddressesContext.Provider value={{ state, dispatch }}>
      {children}
    </BaseAddressesContext.Provider>
  );
};

export const useBaseAddressesStore = () => {
  const { dispatch, state } = useContext(BaseAddressesContext);

  const token = riderToken || riderTokenNew;
  /**
   * 주소 정보 추가
   */
  const create = useCallback(
    async ({ bookId, memo }) =>
      await api.post(`base-addresses/memos`, token, {
        bookId,
        memo,
      }),
    [token],
  );

  /**
   * 주소 정보 목록
   */
  const fetchAll = useCallback(
    async ({ bookId, address, addressRoad, buildingDongNumber }) => {
      const {
        noBuildingDong: _noBuildingDong,
        withBuildingDong: _withBuildingDong,
      } = await api.get(`deliveries/${bookId}/base-addresses/memos/all`, token);

      const noBuildingDong = _noBuildingDong.sort((a, b) => {
        return a.order < b.order ? -1 : 1;
      });

      const withBuildingDong = _withBuildingDong.sort((a, b) => {
        return a.order < b.order ? -1 : 1;
      });

      let _currentBuildingDong = []; // 도로명 주소 일치 / 빌딩동 정보 일치
      let _withBuildingDongWithAddressRoad = []; // 도로명 주소 일치 / 빌딩동 정보 불일치
      let _withBuildingDongWithAddress = []; // 도로명 주소 불일치
      let _noBuildingDongWithAddressRoad = []; // 도로명 주소 일치
      let _noBuildingDongWithAddress = []; // 도로명 주소 불일치

      if (withBuildingDong?.length > 0) {
        withBuildingDong.forEach((el) => {
          if (
            el.buildingDongNumber === buildingDongNumber &&
            addressRoad === el.baseAddress?.addressRoad
          ) {
            _currentBuildingDong.push(el);
          } else if (addressRoad === el.baseAddress?.addressRoad) {
            _withBuildingDongWithAddressRoad.push(el);
          } else if (
            address === el.baseAddress?.address ||
            address + "번지" === el.baseAddress?.address ||
            address === el.baseAddress?.address + "번지"
          ) {
            _withBuildingDongWithAddress.push(el);
          }
        });
      }

      if (noBuildingDong?.length > 0) {
        noBuildingDong.forEach((el) => {
          if (addressRoad === el.baseAddress?.addressRoad) {
            _noBuildingDongWithAddressRoad.push(el);
          } else if (
            address === el.baseAddress?.address ||
            address + "번지" === el.baseAddress?.address ||
            address === el.baseAddress?.address + "번지"
          ) {
            _noBuildingDongWithAddress.push(el);
          }
        });
      }

      let baseAddresses = [
        ..._currentBuildingDong,
        ..._withBuildingDongWithAddressRoad,
        ..._noBuildingDongWithAddressRoad,
        ..._withBuildingDongWithAddress,
        ..._noBuildingDongWithAddress,
        ...noBuildingDong,
      ];

      dispatch({
        type: CONSTANTS.FETCH_ALL,
        baseAddresses: baseAddresses,
        baseAddressesCurrentBuildingDong: _currentBuildingDong,
        baseAddressesWithBuildingDongWithAddressRoad:
          _withBuildingDongWithAddressRoad,
        baseAddressesWithBuildingDongWithAddress: _withBuildingDongWithAddress,
        baseAddressesNoBuildingDongWithAddressRoad:
          _noBuildingDongWithAddressRoad,
        baseAddressesNoBuildingDongWithAddress: _noBuildingDongWithAddress,
      });
    },
    [dispatch, token],
  );

  /**
   * 주소 정보 id 조회
   */
  const fetchBaseAddressId = useCallback(
    async ({ addressRoad }) => {
      const { items } = await api.get(`base-addresses`, token, {
        page: 1,
        pageSize: 1000,
        addressRoad,
      });

      const ordereds = items.sort((a, b) => {
        return a.id > b.id ? -1 : 1;
      });

      dispatch({
        type: CONSTANTS.FETCH_BASE_ADDRESS_ID,
        baseAddressId: ordereds[0]?.id,
      });

      return ordereds[0]?.id;
    },
    [dispatch, token],
  );

  /**
   * 주소 정보 삭제
   */
  const deleteBaseAddress = useCallback(
    async (id) => await api.delete(`base-addresses/memos/${id}`, token),
    [token],
  );

  /**
   * 주소 정보 수정
   */
  const setOrder = useCallback(
    async (id, { order }) =>
      await api.put(`base-addresses/memos/${id}/order`, token, {
        order,
      }),
    [token],
  );

  /**
   * 주소 정보 수정
   */
  const update = useCallback(
    async (id, { memo, baseAddressId, buildingDongNumber }) => {
      let data = { memo };

      if (baseAddressId) {
        data.baseAddressId = baseAddressId;
      }

      if (buildingDongNumber) {
        data.buildingDongNumber = buildingDongNumber;
      }

      return await api.put(`base-addresses/memos/${id}`, token, data);
    },
    [token],
  );

  return {
    state,
    create,
    fetchAll,
    fetchBaseAddressId,
    deleteBaseAddress,
    setOrder,
    update,
  };
};
