import React from "react";
import styled from "styled-components";

const Self = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 12px;
`;

const Label = styled.span``;
const Value = styled.span``;

const CountItem = styled.div`
  font-weight: bold;
  margin-right: 3px;
  margin-left: 3px;

  ${Label} {
    font-size: 12px;
  }

  ${Value} {
    font-size: 18px;
  }

  ${(props) =>
    props.deliveryStarted &&
    `
    color: #14B6C1;
  `}
  ${(props) =>
    props.deliveryPostponed &&
    `
    color: #4350B6;
  `}
  ${(props) =>
    props.deliveryWaiting &&
    `
    color: #FFA800;
  `}
  ${(props) =>
    props.pickupReturn &&
    `
    color: #202020;
  `}
  ${(props) =>
    props.pickupNormal &&
    `
   color: #FC3F00;
  `}
  ${(props) =>
    props.cs &&
    `
   color: #000000;
  `}
`;

const CountSummary = ({ count, limitedHoursCount }) => {
  return (
    <Self>
      <CountItem deliveryStarted>
        <Label>배송</Label>
        <Value>
          {count.deliveriesStarted}({limitedHoursCount})
        </Value>
      </CountItem>
      <CountItem deliveryWaiting>
        <Label>대기</Label>
        <Value>{count.deliveriesBeforeStart}</Value>
      </CountItem>
      <CountItem deliveryPostponed>
        <Label>연기</Label>
        <Value>{count.deliveriesPostponed}</Value>
      </CountItem>
      <CountItem pickupReturn>
        <Label>픽/반</Label>
        <Value>{count.pickupsReturn}</Value>
      </CountItem>
      <CountItem pickupNormal>
        <Label>수거</Label>
        <Value>{count.pickupsNormal}</Value>
      </CountItem>
      <CountItem cs>
        <Label>CS</Label>
        <Value>{count.cses}</Value>
      </CountItem>
    </Self>
  );
};

export default CountSummary;
