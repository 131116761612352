import React, { memo, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";

import Modal from "components/Modal";

import { TMAP, KAKAO, NAVER } from "constants/links";
import {
  STATUS_PICKUP_NORMAL,
  STATUS_PICKUP_RETURN,
  STATUS_DELIVERY_WAITING,
  STATUS_DELIVERY_POSTPONED,
  STATUS_DELIVERY_STARTED,
  STATUS_CS,
} from "Models";

const ModalBody = styled.div`
  padding: 30px 0 0;
  font-size: 16px;
  background-color: #fff;
  border-radius: 0;
  width: 100%;
  margin: 0;
  min-height: 100px;
`;

const FormGroup = styled(Form.Group)`
  margin-bottom: 25px;
  padding: 0 24px;
`;

const FormLabel = styled(Form.Label)`
  font-size: 20px;
  font-weight: bold;
  color: #212121;
`;

const RadioWrap = styled(Form.Group)`
  display: flex;
`;

const FormCheck = styled(Form.Check)`
  flex: 1;

  ${({ flex }) => flex && `flex: ${flex}`}
`;

const Button = styled.button`
  height: 56px;
  width: 100%;
  background-color: #fc3f00;
  border: none;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
`;

const SettingModal = memo(
  ({
    linkOthers,
    statuses,
    currentCenter,
    showHideMarkers,
    showMarkerAddress,
    onSubmit,
  }) => {
    const { handleSubmit, register, reset } = useForm();

    useEffect(() => {
      reset({
        statuses,
        linkOthers,
        currentCenter: currentCenter.toString(),
        showHideMarkers: showHideMarkers.toString(),
        showMarkerAddress: showMarkerAddress?.toString(),
      });
    }, [
      statuses,
      linkOthers,
      currentCenter,
      showHideMarkers,
      showMarkerAddress,
    ]);

    return (
      <Modal backdrop={true} className="backdrop">
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <FormLabel>마커 표시</FormLabel>
              <RadioWrap>
                <FormCheck
                  label="배송"
                  type="checkbox"
                  value={STATUS_DELIVERY_STARTED}
                  name="statuses"
                  {...register("statuses")}
                />
                <FormCheck
                  label="대기"
                  type="checkbox"
                  value={STATUS_DELIVERY_WAITING}
                  name="statuses"
                  {...register("statuses")}
                />
                <FormCheck
                  label="연기"
                  type="checkbox"
                  value={STATUS_DELIVERY_POSTPONED}
                  name="statuses"
                  {...register("statuses")}
                />
              </RadioWrap>
              <RadioWrap>
                <FormCheck
                  label="일반수거"
                  type="checkbox"
                  value={STATUS_PICKUP_NORMAL}
                  name="statuses"
                  {...register("statuses")}
                />
                <FormCheck
                  label="픽반품수거"
                  type="checkbox"
                  value={STATUS_PICKUP_RETURN}
                  name="statuses"
                  {...register("statuses")}
                />
                <FormCheck
                  label="CS"
                  type="checkbox"
                  value={STATUS_CS}
                  name="statuses"
                  {...register("statuses")}
                />
              </RadioWrap>
            </FormGroup>
            <FormGroup>
              <FormLabel>마커 하단 주소값</FormLabel>
              <RadioWrap>
                <FormCheck
                  label="모두표시"
                  type="radio"
                  value="ALL"
                  name="showMarkerAddress"
                  {...register("showMarkerAddress")}
                />
                <FormCheck
                  label="스캔번호표시"
                  type="radio"
                  value="SCAN"
                  name="showMarkerAddress"
                  {...register("showMarkerAddress")}
                />
                <FormCheck
                  label="표시안함"
                  type="radio"
                  value="NONE"
                  name="showMarkerAddress"
                  {...register("showMarkerAddress")}
                />
              </RadioWrap>
            </FormGroup>
            <FormGroup>
              <FormLabel>외부 맵</FormLabel>
              <RadioWrap>
                <FormCheck
                  label="네이버"
                  type="radio"
                  value={NAVER}
                  name="linkOthers"
                  {...register("linkOthers")}
                />
                <FormCheck
                  label="카카오"
                  type="radio"
                  value={KAKAO}
                  name="linkOthers"
                  {...register("linkOthers")}
                />
                <FormCheck
                  label="티맵"
                  type="radio"
                  value={TMAP}
                  name="linkOthers"
                  {...register("linkOthers")}
                />
              </RadioWrap>
            </FormGroup>
            <FormGroup>
              <FormLabel>위치중앙</FormLabel>
              <RadioWrap>
                <FormCheck
                  label="ON"
                  type="radio"
                  value="true"
                  name="currentCenter"
                  {...register("currentCenter")}
                />
                <FormCheck
                  label="OFF"
                  type="radio"
                  value="false"
                  name="currentCenter"
                  {...register("currentCenter")}
                />
              </RadioWrap>
            </FormGroup>
            <FormGroup>
              <FormLabel>외부마커</FormLabel>
              <RadioWrap>
                <FormCheck
                  label="ON"
                  type="radio"
                  value="true"
                  name="showHideMarkers"
                  {...register("showHideMarkers")}
                />
                <FormCheck
                  label="OFF"
                  type="radio"
                  value="false"
                  name="showHideMarkers"
                  {...register("showHideMarkers")}
                />
              </RadioWrap>
            </FormGroup>
            <Button type="submit">저장 ＆ 닫기</Button>
          </Form>
        </ModalBody>
      </Modal>
    );
  },
);

export default SettingModal;
