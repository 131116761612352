import React, { createContext, useContext, useReducer } from "react";
import { defaultSize } from "utils/params";

import { SIZE_MEDIUM } from "constants/themes";

export const CommonContext = createContext({});

const CONSTANTS = {
  SET_SIZE: "SET_SIZE",
};

const INITIAL_STATE = {
  size: defaultSize || SIZE_MEDIUM,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.SET_SIZE:
      return {
        ...state,
        size: action.size,
      };

    default:
      return INITIAL_STATE;
  }
};

export const CommonProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <CommonContext.Provider value={{ state, dispatch }}>
      {children}
    </CommonContext.Provider>
  );
};

export const useCommonStore = () => {
  const { dispatch, state } = useContext(CommonContext);

  const setSize = (size) => {
    dispatch({ type: CONSTANTS.SET_SIZE, size });
  };

  return {
    state,
    setSize,
  };
};
